import React from 'react';
import { Result } from 'antd';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  environment() {
    const url = window.location.origin;
    const stagingRegex = /app-(.*)\.staging\.eligibility\.snpd\.io/;

    if (url.match(/eee\.mismo\.org/)) {
      return url.includes('staging') ? 'staging' : 'production';
    } else if (url.match(stagingRegex)) {
      return url.match(stagingRegex)[1];
    } else {
      return 'development';
    }
  }

  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidMount() {
    const airbrakeId = process.env.REACT_APP_AIRBRAKE_ID;
    const airbrakeKey = process.env.REACT_APP_AIRBRAKE_KEY;
    if (airbrakeId && airbrakeKey) {
      this.airbrake = new Notifier({
        projectId: airbrakeId,
        projectKey: airbrakeKey,
        environment: this.environment()
      });

      this.airbrake.addFilter(notice => {
        notice.context.version = process.env.REACT_APP_BUILD_ID;
        return notice;
      });
    }
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <Result
          status="500"
          title="Sorry, something went wrong"
          subTitle={process.env.NODE_ENV === 'development' && String(this.state.error)}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
