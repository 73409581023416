import React, { useContext } from 'react';
import { Breadcrumb, Button, Layout, Typography, Space, Row, Col } from 'antd';
import { ApiContext } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import AppFooter from '../AppFooter';
import NavSidebar from '../NavSidebar';
import { useTypes } from '../common/Types';

const { Content } = Layout;
const { Title, Text } = Typography;

const LayoutWithCard = ({ children, company, ...props }) => {
  const { user } = useContext(ApiContext);
  const history = useHistory();

  const { humanizeTypes } = useTypes();

  return (
    <React.Fragment>
      <NavSidebar />
      <Layout style={{ padding: '24px 24px 0 24px' }}>
        <Space
          direction="vertical"
          className="site-layout-background"
          style={{ padding: '24px', marginBottom: '24px' }}
        >
          {user.isSuperAdmin() && (
            <Row>
              <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
                <Breadcrumb.Item>
                  <Link to="/companies">Companies</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {company && <Link to={`/companies/${company.id}`}>{company.fullName}</Link>}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          )}
          <Row>
            <Col span={18}>
              <Title style={{ marginBottom: 0, padding: 0 }} data-cy="company">
                {company?.fullName}
              </Title>
            </Col>
            {company && user.isSuperAdmin() && !company.extensions.eEligibilityRoleTypes.includes('settlement_agency') && (
              <Col span={6}>
                <Space style={{ float: 'right' }}>
                  <Button
                    onClick={() => history.push(`/companies/${company.id}/edit`)}
                    size="large"
                    type="primary"
                    data-cy="edit-company"
                  >
                    Edit Company
                  </Button>
                </Space>
              </Col>
            )}
          </Row>
          <Row>
            <Text>
              {company?.extensions?.eEligibilityRoleTypes && company.extensions.eEligibilityRoleTypes.length > 0
                ? 'Industry Segments: ' + humanizeTypes(company.extensions.eEligibilityRoleTypes)
                : ''}
            </Text>
          </Row>
        </Space>
        <Content className="site-layout-background" style={{ padding: '24px 24px 4px 24px' }}>
          {children}
        </Content>
        <AppFooter {...props} />
      </Layout>
    </React.Fragment>
  );
};

export default LayoutWithCard;
