import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import _ from 'lodash';

const { Text } = Typography;

function AdditionalNotes(props) {
  const { text, defaultExpanded } = props;
  const [expanded, setExpanded] = useState(defaultExpanded); // start out expanded when shown in tree

  const truncatedText = _.truncate(text, { length: 40 });
  const expandable = truncatedText !== text; // don't show "More" if there's nothing to add

  const toggleExpanded = e => {
    setExpanded(!expanded);
  };

  return (
    <Text className="search-notes-text">
      {expanded ? text : truncatedText}
      {expandable && (
        <Button type="link" onClick={toggleExpanded}>
          {expanded ? 'Less' : 'More'}
        </Button>
      )}
    </Text>
  );
}

export default AdditionalNotes;
