import { Col, Row, Typography } from 'antd';
import poweredBySnapdocs from './images/powered-by-snapdocs-logo.svg';
import React, { useContext } from 'react';
import { ApplicationConfigurationContext } from './contexts/ApplicationConfigurationProvider';

const { Text } = Typography;

function FooterLogo() {
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);

  return (
    <>
      <Row justify="center">
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <img src={poweredBySnapdocs} alt="Powered by Snapdocs" height="34" />
        </Col>
      </Row>
      {applicationConfiguration.app_version && (
        <Row justify="center">
          <Text style={{ fontSize: '12px', paddingTop: '1em' }}>
            MISMO e-Eligibility Exchange Version {applicationConfiguration.app_version}
          </Text>
        </Row>
      )}
    </>
  );
}
export default FooterLogo;
