import React, { useContext } from 'react';
import { ApiContext } from '../contexts/ApiProvider';

const Pendo = () => {
  const [initialized, setInitialized] = React.useState();
  const { user } = useContext(ApiContext);

  React.useEffect(() => {
    window.pendo?.[initialized ? 'updateOptions' : 'initialize']({
      visitor: {
        id: user?.id,
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        role: user?.role,
        company_id: user?.company?.id,
        company_name: user?.company?.name
      },
      account: { id: user?.company?.id, name: user?.company?.name } // not sure if we should put this in account or visitor
    });
    initialized || setInitialized(true);
  }, [
    user?.id,
    user?.email,
    user?.first_name,
    user?.last_name,
    user?.role,
    user?.company?.id,
    user?.company?.name,
    initialized
  ]);
  return null;
};

export default Pendo;
