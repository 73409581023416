import { ApplicationConfigurationContext } from '../contexts/ApplicationConfigurationProvider';
import { useContext } from 'react';

export const useTypes = () => {
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);
  const fhlbFlipper = applicationConfiguration?.flippers?.federal_home_loan_bank;

  let humanizedCompanyTypesProto = {
    custodian: 'Custodian',
    investor: 'Investor',
    servicer: 'Servicer',
    settlement_agency: 'Settlement Branch',
    subservicer: 'Subservicer',
    title_underwriter: 'Title Underwriter',
    warehouse_lender: 'Warehouse Lender'
  };

  // ordered for the search page
  let orderedCompanyTypesProto = [
    'investor',
    'title_underwriter',
    'settlement_agency',
    'warehouse_lender',
    'servicer',
    'subservicer',
    'custodian'
  ];

  let requirementRootPropertiesProto = {
    state: 'EEligibilityStateRequirements',
    county: 'EEligibilityCountyRequirements',
    custodian: 'EEligibilityCustodianRequirements',
    investor: 'EEligibilityInvestorRequirements',
    servicer: 'EEligibilityServicerRequirements',
    settlement_agency: 'EEligibilitySettlorRequirements',
    subservicer: 'EEligibilitySubservicerRequirements',
    title_underwriter: 'EEligibilityTitleUnderwriterRequirements',
    warehouse_lender: 'EEligibilityWarehouseLenderRequirements'
  };

  if (fhlbFlipper) {
    orderedCompanyTypesProto.push('federal_home_loan_bank');
    humanizedCompanyTypesProto['federal_home_loan_bank'] = 'Federal Home Loan Bank';
    requirementRootPropertiesProto['federal_home_loan_bank'] = 'EEligibilityFederalHomeLoanBankRequirements';
  }

  return {
    humanizedCompanyTypes: humanizedCompanyTypesProto,
    orderedCompanyTypes: orderedCompanyTypesProto,
    alphabetizedCompanyTypes: orderedCompanyTypesProto.slice().sort(),
    humanizedRequirementTypes: {
      state: 'State',
      county: 'Recording Jurisdiction',
      ...humanizedCompanyTypesProto
    },
    requirementRootProperties: requirementRootPropertiesProto,
    uneditableCompanyTypes: ['settlement_agency'],
    orderedRequirementTypes: ['state', 'county', ...orderedCompanyTypesProto],
    humanizeTypes: companyTypes => {
      if (Array.isArray(companyTypes)) {
        return companyTypes.map(type => humanizedCompanyTypesProto[type]).join(', ');
      } else {
        return humanizedCompanyTypesProto[companyTypes];
      }
    }
  };
};
