import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Typography } from 'antd';

const { Text } = Typography;

function FAQLink() {
  return (
    <Row justify="center" style={{ padding: 12 }}>
      <Text>
        Having trouble? You can find our FAQ{' '}
        <Link to={'/contributor_faq'} target="_blank">
          here
        </Link>
        .
      </Text>
    </Row>
  );
}
export default FAQLink;
