import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import LayoutWithCard from '../layouts/LayoutWithCard';
import { API_COMPANIES, ApiContext } from '../contexts/ApiProvider';

import { useTypes } from '../common/Types';
import RequirementsIndexItem from './RequirementIndexItem';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

const getEditableCompanyTypes = user => {
  if (user.isCompanyContributor()) {
    // should be able to just use their contributor types since those are restricted to company types already
    return user.company_user.contributor_types;
  } else if (user.isCompanyAdmin()) {
    return user.company.company_types; // can edit all
  } else {
    // otherwise they probably shouldn't be on this page but just in case
    return [];
  }
};

export default function RequirementsIndex() {
  const { api, user } = useContext(ApiContext);

  const [requirements, setRequirements] = useState();
  const editableTypes = getEditableCompanyTypes(user);

  const params = useParams();
  const companyId = params.id ? params.id : user.company.id;

  const [company, setCompany] = useState();
  const { humanizedCompanyTypes, requirementRootProperties } = useTypes();

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}`).then(response => {
      setCompany(response.data);
    });
  }, [api, companyId]);

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}/company_requirements`).then(response => {
      setRequirements(response.data);
    });
  }, [api, user, companyId]);

  return (
    <LayoutWithCard company={company} showFAQLink={true}>
      <Title data-test="Requirements">Requirements</Title>
      <Row style={{ padding: 8, marginBottom: 16 }}>
        <Col span={12}>
          <Text>
            Submit and maintain digital mortgage closing criteria and requirements to the MISMO e-Eligibility Exchange.
          </Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {Object.entries(humanizedCompanyTypes)
          .filter(([type, title]) => editableTypes.includes(type))
          .map(([type, title]) => {
            const rootProperty = requirementRootProperties[type];
            const requirement =
              requirements && requirements.find(requirement => Object.keys(requirement)[0] === rootProperty);

            return (
              <Col span={8} key={type}>
                <RequirementsIndexItem
                  key={type}
                  type={type}
                  title={title}
                  requirementBody={requirement && requirement[rootProperty]}
                />
              </Col>
            );
          })}
      </Row>
    </LayoutWithCard>
  );
}
