import { Form, Radio } from 'antd';
import React from 'react';
import { formItemStyle } from './GeneratorCommon';
import { convertPathToTag } from '../common/dataTestUtil';

const RadioControl = props => {
  const { handleChange, path, label, visible, schema, uischema } = props;
  return (
    visible && (
      <Form.Item
        name={path.split('.')}
        label={label}
        style={formItemStyle(uischema)}
        rules={[{ required: true, message: 'Please select an option.' }]}
        initialValue={schema.defaultValue}
        data-test={convertPathToTag(path)}
      >
        <Radio.Group onChange={e => handleChange(path, e.target.value)}>
          <Radio value={true} data-test={convertPathToTag(path) + '_Yes'}>
            Yes
          </Radio>
          <Radio value={false} data-test={convertPathToTag(path) + '_No'}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>
    )
  );
};
export default RadioControl;
