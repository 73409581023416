import { Link } from 'react-router-dom';
import { Button, Card } from 'antd';
import { formatDateTime } from '../common/DateTime';

export default function RequirementsIndexItem({ type, title, requirementBody }) {
  const lastModifiedBy = requirementBody && requirementBody.CreatedBy.fullName;
  const lastModifiedAt = requirementBody && requirementBody.CreatedDatetime;
  const modifiedDescription = requirementBody && `Last modified ${formatDateTime(lastModifiedAt)} by ${lastModifiedBy}`;

  return (
    <Card
      actions={[
        <Link to={`/requirements/${type}`}>
          <Button type="primary" data-test={type + '_EditRequirements'}>
            Edit Requirements
          </Button>
        </Link>
      ]}
    >
      <Card.Meta
        title={title}
        description={modifiedDescription}
        data-test={type}
        style={{ height: '75px' }}
      ></Card.Meta>
    </Card>
  );
}
