const formatter = Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short'
});

export const formatDateTime = datetime => {
  return (
    datetime &&
    formatter
      .formatToParts(Date.parse(datetime))
      .map(({ type, value }) => (type === 'literal' && value === ', ' ? ' ' : value))
      .join('')
  );
};
