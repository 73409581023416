import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Space, Table, Typography, Input } from 'antd';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useTypes } from '../common/Types';
import { formatDateTime } from '../common/DateTime';

const { Title, Text } = Typography;
const { Search } = Input;

const apiDirections = {
  ascend: 'asc',
  descend: 'desc'
};

function CompanyUsersTable({ companyId }) {
  const { url } = useRouteMatch();

  const { api } = useContext(ApiContext);

  const [users, setUsers] = useState();
  const [usersLoading, setUsersLoading] = useState(true);

  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });
  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [searchParams, setSearchParams] = useState({});

  const history = useHistory();

  const { humanizedCompanyTypes, humanizeTypes } = useTypes();

  useEffect(() => {
    const indexParams = { ...tableParams, ...searchParams };
    api.get(`${API_COMPANIES}/${companyId}/company_users`, { params: indexParams }).then(response => {
      setUsers(response.data);
      setPageData({
        current: tableParams.page,
        pageSize: response.headers['per-page'],
        total: response.headers.total
      });
      setUsersLoading(false);
    });
  }, [api, companyId, tableParams, searchParams]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      filters: Object.entries(humanizedCompanyTypes).map(([key, name]) => {
        return { text: name, value: key };
      }),
      render: (_, companyUser) => {
        return (
          <React.Fragment>
            <Link to={`${url}/users/${companyUser.id}`}>
              {companyUser.user.first_name} {companyUser.user.last_name}
            </Link>
            <br />
            <Text>{humanizeTypes(companyUser.contributor_types)}</Text>
          </React.Fragment>
        );
      }
    },
    {
      title: 'Last Contributed',
      dataIndex: 'last_contributed_at',
      key: 'last_contributed_at',
      sortDirections: ['descend', 'ascend', 'descend'], // requires some sort of ordering
      sorter: true,
      render: time => formatDateTime(time)
    },
    {
      title: 'Last Logged In',
      key: 'last_logged_in_at',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: companyUser => formatDateTime(companyUser.user.last_logged_in_at)
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current
    };
    if (filters.name) {
      // get the type checkbox filters from the name+types column
      params.contributor_types = filters.name;
    }
    setTableParams(params);
  };

  const updateSearchParams = query => {
    setTableParams(prevParams => {
      return { ...prevParams, page: 1 }; // reset page
    });
    setSearchParams(
      query.length > 0 ? { name: query } : {} // update the name search param
    );
  };

  return (
    <React.Fragment>
      <Title>Users</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12}>
          <Text>
            Manage the Company Admins and e-Eligibility Contributors who will submit your organization’s digital
            mortgage closing criteria and requirements to the MISMO e-Eligibility Exchange.
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Search
              allowClear
              enterButton
              loading={usersLoading && searchParams.name}
              onSearch={updateSearchParams}
              placeholder="Search"
              size="large"
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => history.push(`/companies/${companyId}/users/new`)}
              size="large"
              type="primary"
            >
              Add User
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={users}
        pagination={{ position: ['bottomCenter'], showSizeChanger: false, ...pageData }}
        loading={usersLoading}
        onChange={onTableChange}
        rowKey="id"
        data-cy="users-tbl"
      />
    </React.Fragment>
  );
}

export default CompanyUsersTable;
