import React from 'react';
import { Typography } from 'antd';
import { API_USERS } from '../contexts/ApiProvider';
import Terms from './Terms';

function ContributorTerms() {
  const { Title, Text } = Typography;

  return (
    <Terms postUrl={`${API_USERS}/contributor_terms`} redirectUrl={'/'} termsField={'agreed_to_contributor_terms_at'}>
      <Title className="document-title" level={1}>
        Contributor License Agreement
      </Title>
      <Text>
        This is Contributor License Agreement (this “Agreement”) between MISMO and the Contributor (as defined below)
        identified in the signature block below, relating to contributions of data, information and other related
        intellectual property made from time to time by Contributor to the Mortgage Industry Standards Maintenance
        Organization, Inc. (“MISMO”) for use/incorporation in the service currently referred to as the e-Eligibility
        Exchange, which name may be changed by MISMO from time to time without impacting this Agreement. The purpose of
        this Agreement is to establish the terms of the intellectual property license granted with Contributions (as
        defined below) to MISMO from Contributor. This license is for Contributor’s protection as well as the protection
        of MISMO and its licensees; it does not change any Contributor’s rights to use its Contributions for any other
        purpose.
      </Text>
      <br />
      <br />
      <Text>
        By agreeing to these terms and conditions, Contributor accepts and agrees to the following terms and conditions
        for its present and future Contributions submitted to MISMO. Except for the license granted herein to MISMO and
        those granted access to the e-Eligibility Exchange by MISMO (each a “Licensee”), each Contributor reserves all
        right, title, and interest in and to its Contributions.
      </Text>
      <br />
      <br />
      <ol style={{ paddingLeft: 26 }}>
        <li>
          <Text>
            <u>Definitions.</u>
            <br />
            "Contributor" shall mean the holder or owner of all intellectual property rights in the Contribution, or
            legal entity authorized by such owner, that is making this Agreement with MISMO. For legal entities, the
            entity making a Contribution and all other entities that control, are controlled by, or are under common
            control with that entity are considered to be a single Contributor. For the purposes of this definition,
            "control" means (i) the power, direct or indirect, to cause the direction or management of such entity,
            whether by contract or otherwise, or (ii) ownership of fifty percent (50%) or more of the outstanding
            shares, or (iii) beneficial ownership of such entity.
            <br />
            <br />
            "Contribution(s)" shall mean all data, information, and other related intellectual property, including any
            modifications or additions thereto, that are submitted by Contributor to MISMO for inclusion/incorporation
            in, or documentation of, the e-Eligibility Exchange. For the purposes of this definition, "submitted" means
            any form of electronic, verbal, or written or oral communication sent or delivered to MISMO or its
            representatives, including but not limited to, communication on electronic mailing lists and issue tracking
            systems that are managed by, or on behalf of, MISMO for the purpose of discussing, creating, and improving
            the e-Eligibility Exchange, but excluding communication that is conspicuously marked or otherwise designated
            in writing by Contributor as "Not a Contribution." All Contributions hereunder are made on an “AS IS” basis,
            and, except for those representations set forth below, without warranty or representation of any kind,
            express, implied or statutory.
            <br />
            <br />
            “e-Eligibility Exchange Contractor” shall mean a third party with which MISMO enters a contract for
            e-Eligibility Exchange development and/or support services.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Grant of License.</u> Subject to the terms and conditions of this Agreement, Contributor hereby grants to
            MISMO a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable license under all of its
            intellectual property rights in the Contributions to reproduce, prepare derivative works of, publicly
            display, publicly perform, sublicense, and distribute Contributor’s Contributions and such derivative works
            in connection with the e-Eligibility Exchange or successor offering. MISMO shall have the limited right to
            sublicense to e-Eligibility Exchange Contractor(s) the rights to use and create derivative works from the
            Contributions solely to the extent necessary to allow the e-Eligibility Exchange Contractor to carry out its
            e-Eligibility Exchange development and/or support obligations. Further, subject to the terms and conditions
            of this Agreement, Contributor hereby grants to all Licensees a perpetual, worldwide, non-exclusive,
            no-charge, royalty-free, irrevocable license under all of its intellectual property rights in the
            Contributions to access and use Contributor’s Contributions in connection with its MISMO-licensed use of the
            e-Eligibility Exchange or successor offering.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Grant of Patent License.</u> Without limiting the generality of the foregoing, and subject to the terms
            and conditions of this Agreement, Contributor hereby grants to MISMO a perpetual, worldwide, non-exclusive,
            no-charge, royalty-free, irrevocable (except as stated in this section) patent license to make, have made,
            use, offer to sell, sell, import, and otherwise transfer the e-Eligibility Exchange, where such license
            applies only to those patent claims licensable by Contributor that are necessarily infringed by
            Contributor’s Contribution(s) alone or by combination of Contributor’s Contribution(s) with the
            e-Eligibility Exchange to which such Contribution(s) was submitted.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Contributor Representations.</u>
          </Text>
          <ol type="a" style={{ paddingLeft: 22 }}>
            <li>
              <Text>
                Contributor represents that Contributor is legally authorized, entitled and has the power to grant the
                above license.
              </Text>
            </li>
            <li>
              <Text>
                Contributor represents that each of Contributor’s Contributions is Contributor’s original creation.
                Contributor represents that Contributor’s Contribution submissions include complete details of any
                third-party license or other restriction (including, but not limited to, related patents and trademarks)
                of which Contributor is aware and which are associated with any part of Contributor’s Contributions.
              </Text>
            </li>
            <li>
              <Text>
                Contributor further represents and warrants that, to Contributor’s knowledge at the time of submission
                (without any duty to perform a patent search), the submission to MISMO and the use of the Contribution,
                or any portion thereof, by MISMO or Licensees under the terms and conditions of this Agreement will not
                infringe or violate any intellectual property rights of any third party.
              </Text>
            </li>
            <br />
            <li type="none">
              <Text>
                Contributor agrees to notify MISMO of any facts or circumstances of which Contributor becomes aware that
                would make these representations and warranties inaccurate in any respect.
              </Text>
            </li>
          </ol>
          <br />
        </li>
        <li>
          <Text>
            <u>Attribution.</u> MISMO may, as it determines in its sole discretion, publicly recognize Contributor as a
            contributor to the e-Eligibility Exchange and Contributor hereby grants MISMO the right to use its name in
            such attribution.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Updates.</u> Contributor is expected to either (a) update Contributor’s Contributions from time to time
            to keep them current and useful to the e-Eligibility Exchange or (b) promptly inform MISMO that the
            Contributions are no longer current when it becomes aware, so that MISMO can take appropriate action, as it
            determines in its sole discretion.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>e-Eligibility Exchange.</u> As between Contributor and MISMO, MISMO owns and will retain all rights title
            and interest in the e-Eligibility Exchange. Nothing in this Agreement or arising from Contributor’s rights
            in its Contributions are intended to give Contributor any rights in the e-Eligibility Exchange. Access to
            the e-Eligibility Exchange will be made on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
            either express or implied. Submission of a Contribution does not guarantee inclusion of the Contribution in
            the e-Eligibility Exchange which inclusion will be determined by MISMO in its sole discretion.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Governing Law.</u> This Agreement shall be governed by and construed in accordance with the laws of the
            District of Columbia without giving effect to its principles of conflict of laws. Any legal proceedings
            commenced by MISMO or Contributors in respect to this Agreement naming MISMO (or its parent company, the
            Mortgage Bankers Association) as a party shall be commenced in the Federal District Court in the District of
            Columbia or, if the federal district court in District of Columbia does not have jurisdiction, the Superior
            Court of the District of Columbia.
            <br />
            <br />
          </Text>
        </li>
      </ol>
    </Terms>
  );
}

export default ContributorTerms;
