import React, { useContext, useEffect, useState } from 'react';

import { API_CRITERIA_SCHEMAS, API_STATES, ApiContext } from '../contexts/ApiProvider';
import { useParams } from 'react-router-dom';
import RequirementsForm from './RequirementsForm';

function StateRequirementsForm() {
  const { id: stateId } = useParams();

  const [schema, setSchema] = useState();
  const [state, setState] = useState();
  const [requirements, setRequirements] = useState();

  const { api } = useContext(ApiContext);

  useEffect(() => {
    api.get(`${API_CRITERIA_SCHEMAS}/state`).then(response => {
      setSchema(response.data);
    });
  }, [api]);

  useEffect(() => {
    api.get(`${API_STATES}/${stateId}`).then(response => {
      setState(response.data);
    });

    api.get(`${API_STATES}/${stateId}/locality_requirements`).then(response => {
      setRequirements(response.data || {});
    });
  }, [api, stateId]);

  return (
    <RequirementsForm
      title={state?.stateName}
      type="state"
      requirements={requirements}
      schema={schema}
      showAdditionalRequirements={false}
      showRemoveRequirements={false}
      postTo={`${API_STATES}/${stateId}/locality_requirements`}
      afterSuccessPath={'/requirements/states'}
    />
  );
}

export default StateRequirementsForm;
