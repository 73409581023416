import { Form, Input } from 'antd';
import React from 'react';
import { formItemStyle } from './GeneratorCommon';
import { convertPathToTag } from '../common/dataTestUtil';

const TextBox = props => {
  const { path, label, visible, schema, uischema } = props;
  const { TextArea } = Input;
  return (
    visible && (
      <Form.Item
        name={path.split('.')}
        label={label}
        style={formItemStyle(uischema)}
        initialValue={schema.defaultValue}
        data-test={convertPathToTag(path)}
      >
        <TextArea rows={3} maxLength="400" />
      </Form.Item>
    )
  );
};
export default TextBox;
