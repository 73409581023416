import { useTypes } from '../common/Types';
import { Form, Input, Select, Space, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;
const { Option } = Select;

const userTypeDescription = isContributor => {
  return isContributor
    ? 'Contributors can edit e-Eligibility data for your organization based on chosen industry segments assigned (below).'
    : 'Company Admins may add or remove other Company Admins and Contributors, and can edit e-Eligibility data for their organization.';
};

const generateOnValuesChange = (
  apiErrors,
  setApiErrors,
  showWarning,
  setShowWarning,
  setIsContributor,
  form,
  company
) => {
  return changedValues => {
    // clear the api-side error after a change to reset for FE-side validations
    for (const key in changedValues) {
      if (apiErrors[key]) {
        delete apiErrors[key];
        setApiErrors({ ...apiErrors });
      }
    }
    if (showWarning) {
      setShowWarning(Object.keys(apiErrors).length > 0);
    }
    if (changedValues.role) {
      const contributor = changedValues.role === 'contributor';
      setIsContributor(contributor);
      if (contributor) {
        form.setFieldsValue({ contributor_types: company.extensions.eEligibilityRoleTypes });
      }
    }
  };
};

const extractParams = values => {
  const params = {};
  for (const key in values) {
    if (key.startsWith('user.')) {
      params[key.replace('user.', '')] = values[key];
    } else {
      params[key] = values[key];
    }
  }
  return params;
};

const CompanyUserFormFields = props => {
  const { company, isContributor, apiErrors, disableRoleSelect } = props;
  const { alphabetizedCompanyTypes, humanizedCompanyTypes } = useTypes();

  const contributorTypeOptions = companyTypes =>
    alphabetizedCompanyTypes
      .filter(companyType => companyTypes.includes(companyType))
      .map(companyType => (
        <Select.Option key={companyType} value={companyType}>
          {humanizedCompanyTypes[companyType]}
        </Select.Option>
      ));

  return (
    <React.Fragment>
      <Space>
        <Form.Item
          name="user.first_name"
          label="First name"
          rules={[{ required: true, message: 'First name is required.' }]}
        >
          <Input data-cy="first-name" />
        </Form.Item>
        <Form.Item
          name="user.last_name"
          label="Last name"
          rules={[{ required: true, message: 'Last name is required.' }]}
        >
          <Input data-cy="last-name" />
        </Form.Item>
      </Space>
      <Form.Item
        name="user.email"
        label="Email address"
        help={apiErrors['user.email']}
        validateStatus={apiErrors['user.email'] && 'error'}
        rules={[{ required: true, type: 'email', message: 'Valid email address is required.' }]}
        normalize={value => value.trim()}
      >
        <Input data-cy="email" />
      </Form.Item>
      <Form.Item label="User type" name="role">
        <Select data-cy="user-type" disabled={disableRoleSelect}>
          <Option value="admin">Admin</Option>
          {company.extensions?.eEligibilityRoleTypes.length > 0 && <Option value="contributor">Contributor</Option>}
        </Select>
      </Form.Item>
      <Form.Item>
        <Text type="secondary">{userTypeDescription(isContributor)}</Text>
      </Form.Item>
      {isContributor && company.extensions?.eEligibilityRoleTypes && (
        <Form.Item
          name="contributor_types"
          label="Industry segment"
          rules={[{ required: true, type: 'array', min: 1, message: '1 Minimum Required.' }]}
        >
          <Select mode="multiple" placeholder="Select one or more">
            {contributorTypeOptions(company.extensions.eEligibilityRoleTypes)}
          </Select>
        </Form.Item>
      )}
    </React.Fragment>
  );
};

export { extractParams, generateOnValuesChange, CompanyUserFormFields };
