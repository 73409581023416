import React from 'react';
import acceptedIconSmall from '../images/search_accepted_sm.svg';
import notAcceptedIconSmall from '../images/search_not_accepted_sm.svg';
import { convertPathToTag } from '../common/dataTestUtil';

export function CheckTreeNodeAccepted({ label, dataPath }) {
  return (
    <div data-test={convertPathToTag(dataPath)}>
      <img src={acceptedIconSmall} alt="Accepted" className="search-tree-node-icon" /> {label}
    </div>
  );
}

export function CheckTreeNodeNotAccepted({ label, dataPath }) {
  return (
    <div data-test={convertPathToTag(dataPath)}>
      <img src={notAcceptedIconSmall} alt="Not Accepted" className="search-tree-node-icon" /> {label}
    </div>
  );
}

function CheckTreeNodeLabel(props) {
  const { label, accepted, dataPath } = props;
  if (accepted) {
    return <CheckTreeNodeAccepted label={label} dataPath={dataPath} />;
  } else if (accepted === false) {
    return <CheckTreeNodeNotAccepted label={label} dataPath={dataPath} />;
  } else {
    return <div>{label}</div>;
  }
}

export default CheckTreeNodeLabel;
