import React, { useContext, useEffect, useState } from 'react';
import { Form, Select, Space } from 'antd';
import { ApiContext, API_STATES, API_COUNTIES } from '../contexts/ApiProvider';

const LocalitySelect = ({ value = {}, onChange, updateSearchingNames }) => {
  const [states, setStates] = useState([]);
  const [counties, setCounties] = useState([]);

  const { api } = useContext(ApiContext);

  useEffect(() => {
    api.get(API_STATES, { params: { sort_by: 'abbreviation', per_page: 100 } }).then(response => {
      setStates(response.data);
    });
  }, [api]);

  useEffect(() => {
    setCounties([]);
    if (value.state !== undefined) {
      api.get(API_COUNTIES, { params: { state: value.state, sort_by: 'name', per_page: 300 } }).then(response => {
        setCounties(response.data);
      });
    }
  }, [api, value.state]);

  const getStateName = id => {
    return id && states.find(state => state.id === id)?.stateName;
  };

  const getCountyName = id => {
    return id && counties.find(county => county.id === id)?.countyName;
  };

  const triggerUpdate = newValues => {
    const combinedValues = { state: value.state, county: value.county, ...newValues };
    onChange?.(combinedValues);
    updateSearchingNames?.({
      state: getStateName(combinedValues.state),
      county: getCountyName(combinedValues.county)
    });
  };

  return (
    <Space size={[20, 0]} wrap>
      <Form.Item label="State" htmlFor="state" className="search-state-dropdown">
        <Select
          id="state"
          value={value.state}
          onChange={state => triggerUpdate({ state, county: undefined })}
          allowClear={true}
          showSearch
          size="large"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          data-cy="state"
        >
          {states.map(state => (
            <Select.Option key={state.stateCode} value={state.id}>
              {state.stateCode}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Recording Jurisdiction" htmlFor="county" style={{ width: 312 }}>
        <Select
          id="county"
          value={value.county}
          onChange={county => triggerUpdate({ county })}
          allowClear={true}
          showSearch
          disabled={counties.length === 0}
          size="large"
          data-cy="county"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {counties
            .sort((a, b) => a.countyName.localeCompare(b.countyName))
            .map(county => (
              <Select.Option key={county.id} value={county.id}>
                {county.countyName}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Space>
  );
};

export default LocalitySelect;
