import './ApiKeyIndex.less';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Space, Typography, Table } from 'antd';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import LayoutBasic from '../layouts/LayoutBasic';
import NewApiKeyModal from './NewApiKeyModal';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { formatDateTime } from '../common/DateTime';
import { Redirect } from 'react-router-dom';

const { Title, Text } = Typography;

const apiDirections = {
  ascend: 'asc',
  descend: 'desc'
};

function ApiKeyIndex() {
  const { api, user } = useContext(ApiContext);
  const [apiKeys, setApiKeys] = useState([]);

  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });
  const [loading, setLoading] = useState(true);

  const companyId = user.company.id;
  const apiEnabled = user.company.api_enabled;

  const loadTokens = () => {
    if (apiEnabled) {
      setLoading(true);
      api.get(`${API_COMPANIES}/${companyId}/api_tokens`, { params: tableParams }).then(response => {
        setApiKeys(response.data);
        setPageData({
          current: tableParams.page,
          pageSize: response.headers['per-page'],
          total: response.headers.total
        });
        setLoading(false);
      });
    }
  };

  useEffect(loadTokens, [api, tableParams, companyId, apiEnabled]);

  const revokeToken = token => {
    setLoading(true);
    api.delete(`${API_COMPANIES}/${companyId}/api_tokens/${token.apiKey}`).then(() => {
      loadTokens();
    });
  };

  const confirmRevoke = token => {
    Modal.confirm({
      icon: <InfoCircleOutlined />,
      title: 'This action is permanent',
      onOk: () => revokeToken(token),
      okText: 'Ok',
      content: 'Revoking an API key permanently disables it. This action cannot be undone. Are you sure?'
    });
  };

  const showNewKeyModal = () => {
    Modal.info({
      title: 'API Key Details',
      icon: <InfoCircleOutlined />,
      content: <NewApiKeyModal api={api} user={user} />,
      okText: 'Ok',
      width: 588,
      afterClose: loadTokens
    });
  };

  const onTableChange = (pagination, _, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current
    };
    setTableParams(params);
  };

  const columns = [
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      defaultSortOrder: 'ascend',
      key: 'apiKey',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true
    },
    {
      title: 'Created By',
      dataIndex: ['createdBy', 'fullName'],
      defaultSortOrder: 'ascend',
      key: 'createdByName',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true
    },
    {
      title: 'Last Accessed',
      defaultSortOrder: 'ascend',
      key: 'lastUsedDatetime',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, apiKey) => formatDateTime(apiKey.lastUsedDatetime) || 'Never'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, apiKey) => (apiKey.active === true ? 'Enabled' : 'Disabled')
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, apiKey) => {
        return apiKey.active === true ? (
          <Button className="text-button" type="link" onClick={() => confirmRevoke(apiKey)}>
            Revoke
          </Button>
        ) : null;
      }
    }
  ];

  return apiEnabled ? (
    <LayoutBasic showFAQLink={true}>
      <Title>Integrations</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12}>
          <Text>
            Add and manage your API keys. For more information, please see the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://eee-api.readme.io/reference/getting-started-with-your-api'}
            >
              MISMO e-Eligibility Exchange API Documentation
            </a>
            .
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Button icon={<PlusOutlined />} onClick={showNewKeyModal} size="large" type="primary" data-cy="add-api-key">
              New API Key
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={apiKeys}
        loading={loading}
        onChange={onTableChange}
        pagination={{ position: ['bottomCenter'], showSizeChanger: false, ...pageData }}
        rowKey="apiKey"
        data-cy="companies-tbl"
        rowClassName={(apiKey, _) => (apiKey.active !== true ? 'disabled-row' : null)}
      />
    </LayoutBasic>
  ) : (
    <Redirect to={'/'} />
  );
}

export default ApiKeyIndex;
