import { Form, Input, Space } from 'antd';
import React from 'react';

const generateOnValuesChange = (apiErrors, setApiErrors, showWarning, setShowWarning, form) => {
  return changedValues => {
    // clear the api-side error after a change to reset for FE-side validations
    for (const key in changedValues) {
      if (apiErrors[key]) {
        delete apiErrors[key];
        setApiErrors({ ...apiErrors });
      }
    }
    if (showWarning) {
      setShowWarning(Object.keys(apiErrors).length > 0);
    }
  };
};

const SuperAdminFormFields = props => {
  const { apiErrors } = props;
  return (
    <React.Fragment>
      <Space>
        <Form.Item
          name="first_name"
          label="First name"
          rules={[{ required: true, message: 'First name is required.' }]}
        >
          <Input data-cy="first-name" />
        </Form.Item>
        <Form.Item name="last_name" label="Last name" rules={[{ required: true, message: 'Last name is required.' }]}>
          <Input data-cy="last-name" />
        </Form.Item>
      </Space>
      <Form.Item
        name="email"
        label="Email address"
        help={apiErrors['email']}
        validateStatus={apiErrors['email'] && 'error'}
        rules={[{ required: true, type: 'email', message: 'Valid email address is required.' }]}
        normalize={value => value.trim()}
      >
        <Input data-cy="email" />
      </Form.Item>
    </React.Fragment>
  );
};

export { generateOnValuesChange, SuperAdminFormFields };
