import React, { useContext, useEffect, useState } from 'react';

import { API_COMPANIES, API_CRITERIA_SCHEMAS, ApiContext } from '../contexts/ApiProvider';
import { useParams } from 'react-router-dom';
import RequirementsForm from './RequirementsForm';
import { useTypes } from '../common/Types';

const description = `Submit your organization’s digital mortgage closing criteria and requirements for display in the MISMO
e-Eligibility Exchange. The data will only be visible to members of MISMO that are logged in to the MISMO
e-Eligibility Exchange.`;

function CompanyRequirementsForm() {
  const { type: requirementsType } = useParams();

  const [schema, setSchema] = useState();
  const [requirements, setRequirements] = useState();
  const [finishedLoadingRequirements, setFinishedLoadedRequirements] = useState(false);

  const { api, user } = useContext(ApiContext);
  const { humanizedCompanyTypes } = useTypes();

  useEffect(() => {
    api.get(`${API_CRITERIA_SCHEMAS}/${requirementsType}`).then(response => {
      setSchema(response.data);
    });
  }, [api, requirementsType]);

  useEffect(() => {
    api.get(`${API_COMPANIES}/${user.company.id}/company_requirements`).then(response => {
      setRequirements(response.data);
      setFinishedLoadedRequirements(true);
    });
  }, [api, user]);

  const rootProperty = schema && Object.keys(schema.data_schema.properties)[0];
  const currentRequirement =
    requirements && requirements.find(requirement => Object.keys(requirement)[0] === rootProperty);

  return (
    <RequirementsForm
      title={`${humanizedCompanyTypes[requirementsType]} Requirements`}
      type={requirementsType}
      finishedLoadingRequirements={finishedLoadingRequirements}
      requirements={currentRequirement}
      schema={schema}
      showAdditionalRequirements={true}
      showRemoveRequirements={true}
      description={description}
      postTo={`${API_COMPANIES}/${user.company.id}/company_requirements`}
      afterSuccessPath={`/requirements`}
    />
  );
}

export default CompanyRequirementsForm;
