import React from 'react';
import { Layout, Typography } from 'antd';
import AppFooter from '../AppFooter';
import editUserImage from './images/mismo-eee-faq-edit-user.png';
import usersIndexImage from './images/mismo-eee-faq-users-index.png';

const { Title, Text } = Typography;
const { Content } = Layout;

function ContributorFAQ() {
  return (
    <Layout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="document-content">
          <Content className="site-layout-background" style={{ padding: '24px 24px 4px 24px' }}>
            <Title className="document-title" level={1}>
              Contributor FAQ
            </Title>
            <Text strong={true}>How do I add another user to my company?</Text>
            <br />
            <br />
            <Text>
              In order to add another user:
              <br />
              <br />
              1. Click <b>Users</b> from the Left Hand Navigation.
              <br />
              2. Click the <b>Add User</b> button on the right side of your Company Profile.
              <br />
              3. Enter the <b>Name</b>, <b>Email</b>, and select a <b>User Type</b>.
              <br />
              4. Click <b>Add User</b>.
            </Text>
            <br />
            <br />
            <img src={usersIndexImage} alt="Users Page" />
            <br />
            <br />
            <Text strong={true}>What is the difference between the “Admin” and “Contributor” user type?</Text>
            <br />
            <br />
            <Text>
              The primary difference between these two user types is the amount of functionality they have access to
              within their Company’s profile.
              <br />
              <br />
              <b>Admins</b> can add other Users to the Company profile, update/edit the e-Eligibility Requirements,
              create/revoke API Keys, and view e-Eligibility Exchange Results.
              <br />
              <br />
              <b>Contributors</b> will be able to access and maintain eligibility criteria for the Industry Segments
              that have been assigned to their User Profile and view e-Eligibility Exchange Results.
            </Text>
            <br />
            <br />
            <Text strong={true}>How do I remove a user from my company’s eligibility profile?</Text>
            <br />
            <br />
            <Text>
              If you need to remove a user from your Company’s profile, you can follow these steps:
              <br />
              <br />
              1. Click <b>Users</b> in the Left Hand Navigation.
              <br />
              2. Select that user from your Company User List.
              <br />
              3. Click <b>Remove User</b> button as displayed in the screenshot below.
              <br />
              4. Click <b>Remove User</b> to confirm before the action is complete as this cannot be undone.
            </Text>
            <br />
            <br />
            <img src={editUserImage} alt="Edit Users Page" />
            <br />
            <br />
            <Text strong={true}>
              My organization provides more services than I’m seeing in our “Requirements” section. How do I get more
              added?
            </Text>
            <br />
            <br />
            <Text>
              If you would like to have other industry segments added to your Company’s profile you can reach out to
              MISMO at <a href={'mailto:eee@mismo.org'}>eee@mismo.org</a> to request an update. Please include your
              Name, the Company Name and Industry Segment(s) you are looking to have added to the Company profile.
            </Text>
            <br />
            <br />
            <Text strong={true}>
              I’m not sure how to answer one of the eligibility questions. Who can I reach out to for clarity?
            </Text>
            <br />
            <br />
            <Text>
              If you are unsure of how to answer a question for a given industry segment, or there is a value you feel
              is missing from one of the question options you can reach out to MISMO at{' '}
              <a href={'mailto:eee@mismo.org'}>eee@mismo.org</a>. Please include your name, company and what question
              you find unclear or the value you think should be added in order to answer a question properly.
            </Text>
            <br />
            <br />
            <Text strong={true}>How will I know that my data has been published to the exchange?</Text>
            <br />
            <br />
            <Text>
              Data gets published to the exchange immediately, but you can always double check by:
              <br />
              <br />
              1. Navigating to the MISMO e-Eligibility search page.
              <br />
              2. Search for your company.
              <br />
              3. Review the results.
            </Text>
            <br />
            <br />
            <Text strong={true}>
              Can I refer other companies to the MISMO Exchange to provide their e-eligibility data?
            </Text>
            <br />
            <br />
            <Text>
              If there are partners that you work with who are not in the exchange, you can reach out to MISMO at{' '}
              <a href={'mailto:eee@mismo.org'}>eee@mismo.org</a>. Please provide the Company you would like to see added
              and Company Contact Information, if available.
            </Text>
            <br />
            <br />
            <Text strong={true}>I’m not seeing a way to connect to the MISMO e-Eligibility Exchange via API?</Text>
            <br />
            <br />
            <Text>
              Integrating directly with the MISMO e-Eligibility Exchange API is functionally that must be approved by
              the MISMO Team. Please email your Company Name and Contact information to{' '}
              <a href={'mailto:eee@mismo.org'}>eee@mismo.org</a> with the subject line “API Access Request.” The team
              will respond with further instructions.
            </Text>
            <br />
            <br />
            <Text>
              <b>Not seeing what you’re looking for?</b> You can submit a generic request{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://app.smartsheet.com/b/form/03ef63fa9a8a4af7abf81ab66d99b1e2"
              >
                here
              </a>
              .
            </Text>
          </Content>
        </div>
      </div>
      <AppFooter backgroundColor="#f0f2f5" />
    </Layout>
  );
}
export default ContributorFAQ;
