import { Typography, Form, Space, Button, Layout, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiContext, API_REQUIREMENTS_SEARCH } from '../contexts/ApiProvider';
import SearchFooter from '../SearchFooter';
import CompanySelect from './CompanySelect';
import LocalitySelect from './LocalitySelect';
import Results from './Results';
import { useTypes } from '../common/Types';

const { Content } = Layout;
const { Title, Text } = Typography;

function Search() {
  const [form] = Form.useForm();
  const { api } = useContext(ApiContext);
  const [formIsValid, setFormIsValid] = useState(false);

  const [searchingNames, setSearchingNames] = useState({}); // company/locality names currently selected in dropdown
  const [searchedNames, setSearchedNames] = useState({}); // persisted names from last search
  const [requirements, setRequirements] = useState();
  const [searching, setSearching] = useState(false);

  const { orderedCompanyTypes } = useTypes();

  const clearData = () => {
    form.resetFields();
    onFieldsChange();
    setSearchingNames({});
    setRequirements();
  };

  const onFormFinish = values => {
    const { locality, ...companyValues } = values;
    const adjustedValues = Object.assign(companyValues, locality);

    setRequirements(null);
    setSearchedNames(searchingNames);
    setSearching(true);

    api.get(API_REQUIREMENTS_SEARCH, { params: adjustedValues }).then(response => {
      setSearching(false);
      setRequirements(response.data);
    });
  };

  // keep track of the names selected the dropdowns so we can display them in the results
  const updateSearchingNames = newNames => {
    setSearchingNames({ ...searchingNames, ...newNames });
  };

  const onFieldsChange = () => {
    const fields = form.getFieldsValue();
    const atLeastOneFieldSelected = Object.values(fields).filter(v => {
      return v && typeof v === 'object' ? Object.values(v).some(f => f !== undefined) : v !== undefined;
    }).length;
    setFormIsValid(atLeastOneFieldSelected);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="search-form-content">
        <div className="search-header">
          <Title level={2}>e-Eligibility Search</Title>
          <Text>Check digital mortgage e-Eligibility requirements and closing acceptance criteria.</Text>
        </div>
        <Spin spinning={searching}>
          <Content className="site-layout-background" style={{ padding: '24px 24px 4px 24px' }}>
            <Form form={form} layout="vertical" onFinish={onFormFinish} onFieldsChange={onFieldsChange}>
              <Title level={3}>Property Location</Title>
              <Form.Item name="locality">
                <LocalitySelect updateSearchingNames={updateSearchingNames} />
              </Form.Item>
              <Title level={3}>Industry Segments</Title>
              <Space size={[20, 0]} wrap>
                {orderedCompanyTypes.map(segment => {
                  return (
                    <CompanySelect
                      key={segment}
                      name={segment}
                      segment={segment}
                      updateSearchingNames={updateSearchingNames}
                      style={{ width: 312 }}
                    />
                  );
                })}
              </Space>
              <Form.Item>
                <Space>
                  <Button disabled={!formIsValid} size="large" type="primary" htmlType="submit" data-cy="submit">
                    Check e-Eligibility
                  </Button>
                  <Button disabled={!formIsValid} size="large" type="link" onClick={clearData} data-cy="clear">
                    Clear Form
                  </Button>
                </Space>
              </Form.Item>
              <Form.Item>
                <Text style={{ fontSize: '12px' }}>
                  All search results are subject to the MISMO e-Eligibility Exchange{' '}
                  <Link to="/viewer_terms" target="_blank">
                    terms of service
                  </Link>
                  .
                </Text>
              </Form.Item>
            </Form>
          </Content>
        </Spin>
        <Results searchedNames={searchedNames} requirements={requirements} />
        <SearchFooter />
      </div>
    </div>
  );
}

export default Search;
