import { Dropdown, Button, Menu, Col, Layout, Row, Space, Typography, Avatar, Grid } from 'antd';
import logo from './images/mismo_logo.png';
import React, { useContext } from 'react';
import { ApiContext } from './contexts/ApiProvider';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';

const { Header } = Layout;
const { Title } = Typography;

function truncated_email(email) {
  if (email === undefined) {
    return '';
  }

  const maxLength = 25;

  if (email.length <= maxLength) {
    return email;
  }

  const emailParts = email.split('@');

  // if the email is somehow invalid, or if the domain name is long, just truncate the original string
  if (emailParts.length !== 2 || emailParts[1].length > maxLength - 2) {
    return `${email.slice(0, maxLength - 3)}...`;
  }

  // subtract the "...@" and the domain length to determine length of first segment
  const firstPartLength = maxLength - 4 - emailParts[1].length;

  return `${emailParts[0].slice(0, firstPartLength)}...@${emailParts[1]}`;
}

const userIcon = () => {
  return <Avatar shape="circle" size="small" icon={<UserOutlined />} />;
};

function AppHeader() {
  const { user, logout } = useContext(ApiContext);
  let location = useLocation();

  const screens = Grid.useBreakpoint();
  const showEmail = screens.md;

  const showManageLink = user && !user.isViewer() && location.pathname === '/search' && showEmail;

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={logout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <Row>
        <Col>
          <Space>
            <img src={logo} alt="MISMO" />
            <Title
              level={screens.xs ? 5 : 4}
              style={{
                marginBottom: 0,
                color: '#235099'
              }}
            >
              e-Eligibility Exchange
            </Title>
          </Space>
        </Col>
        <Col flex="auto" />
        {user ? (
          <Col>
            {showManageLink ? (
              <Button type="link">
                <Link to={'/'}>Manage e-Eligibility</Link>
              </Button>
            ) : null}

            <Dropdown overlay={menu}>
              <Button type="link" aria-label="User Menu">
                {showEmail ? truncated_email(user.email) : userIcon()} <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        ) : null}
      </Row>
    </Header>
  );
}
export default AppHeader;
