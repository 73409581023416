import React, { useContext, useEffect, useState } from 'react';

import { Breadcrumb, Button, Form, message, Space, Spin, Typography } from 'antd';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useHistory, useParams } from 'react-router-dom';
import { extractParams, generateOnValuesChange, CompanyUserFormFields } from './CompanyUserFormCommon';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;

function CompanyUserNew() {
  const { company_id: companyId } = useParams();

  const history = useHistory();
  const { api } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [apiErrors, setApiErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false); // let the user know errors occured
  const [company, setCompany] = useState({});
  const [isContributor, setIsContributor] = useState(false);

  const setErrors = errors => {
    setLoading(false);
    setApiErrors(errors);
    setShowWarning(true);
  };

  const saveUser = async attributes => {
    try {
      await api.post(`${API_COMPANIES}/${companyId}/company_users`, attributes);
      setLoading(false);
      message.success(`Success - new ${isContributor ? 'Contributor' : 'Company Admin'} created.`);
      history.push(`/companies/${companyId}`);
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setLoading(true);
    setApiErrors({});
    setShowWarning(null);

    await saveUser(extractParams(values));
  };

  const onValuesChange = generateOnValuesChange(
    apiErrors,
    setApiErrors,
    showWarning,
    setShowWarning,
    setIsContributor,
    form,
    company
  );

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}`).then(response => {
      setCompany(response.data);
      setLoading(false);
    });
  }, [api, companyId]);

  return (
    <LayoutBasic showFAQLink={true}>
      <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={`/companies/${companyId}`}>{company.fullName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>Add User</Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading}>
        <Title>Add User</Title>
        <Space direction="vertical">
          <Text>Add a new user to your organization.</Text>
          <Form
            form={form}
            initialValues={{ role: 'admin' }}
            layout="vertical"
            size="large"
            onFinish={onFormFinish}
            onFinishFailed={() => {
              setShowWarning(true);
            }} // just draw attention to FE errors
            onValuesChange={onValuesChange}
            requiredMark={false}
            style={{ maxWidth: 324 }}
          >
            <CompanyUserFormFields company={company} isContributor={isContributor} apiErrors={apiErrors} />
            {showWarning && <Text type="danger">Could not add User. See messages above.</Text>}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} data-cy="add-user">
                Add User
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </LayoutBasic>
  );
}

export default CompanyUserNew;
