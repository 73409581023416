import React, { useState, createContext, useEffect, useCallback } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import Heap from '../Heap';

export const ApiContext = createContext({});

const userHelpers = {
  isSuperAdmin() {
    return this.role === 'super_admin';
  },
  isCompanyAdmin() {
    return this.role === 'admin';
  },
  isCompanyContributor() {
    return this.role === 'contributor';
  },
  isViewer() {
    return this.role === 'viewer';
  }
};

const useStateWithStorage = key => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      try {
        return JSON.parse(storedValue);
      } catch {} // don't blow up reading old stored values
    }
    return storedValue;
  });

  const setValueWithStorage = newValue => {
    if (newValue != null) {
      localStorage.setItem(key, JSON.stringify(newValue));
    } else {
      localStorage.removeItem(key);
    }
    setValue(newValue);
  };
  return [value, setValueWithStorage];
};

export const baseUrl = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else if (window.location.origin === 'http://localhost:4000') {
    return 'http://localhost:4001';
  } else if (['staging.eee.mismo.org', 'eee.mismo.org'].includes(window.location.host)) {
    return window.location.origin.replace('eee.mismo.org', 'eee-api.mismo.org');
  } else {
    return window.location.origin.replace('app', 'api');
  }
};

export const api = axios.create({
  baseURL: baseUrl()
});

export const ApiProvider = ({ children }) => {
  const [jwt, setJwt] = useStateWithStorage('jwtToken');
  const [user, setUser] = useStateWithStorage('userData');
  const [idpLogoutPath, setIdpLogoutPath] = useState(null);
  const [loading, setLoading] = useState(false);
  const setError = useState()[1];
  const [errorHandlingReady, setErrorHandlingReady] = useState(false);

  if (user) {
    Object.assign(user, userHelpers);
  }

  const logout = useCallback(
    (idpLogout = true) => {
      // TODO: This is re-rendering twice (once for each state update), we could optimize it to once
      setJwt(null);
      setUser(null);

      if (idpLogout && idpLogoutPath) {
        setLoading(true);
        const oidcLogoutUrl = new URL(baseUrl());
        oidcLogoutUrl.pathname = idpLogoutPath;
        window.location.assign(oidcLogoutUrl);
      }
    },
    [setJwt, setUser, idpLogoutPath]
  );

  useEffect(() => {
    if (jwt != null) {
      api.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
    } else {
      delete api.defaults.headers.common['Authorization'];
    }
  }, [jwt]);

  useEffect(() => {
    try {
      if (jwt) {
        const expMs = 1000 * JSON.parse(atob(jwt.split('.')[1])).exp;
        if (expMs < Date.now()) {
          console.log('Logging out due to expired token');
          logout(false);
        }
      }
    } catch {} // could log out here but in case my parsing is broken, the 401 interceptor will handle an invalid JWT
  }, [logout, jwt]);

  useEffect(() => {
    const unauthorizedInterceptor = api.interceptors.response.use(
      success => {
        return success;
      },
      error => {
        // re-login if the jwt is expired
        if (error.response && error.response.status === 401) {
          logout(false);
        }
        return Promise.reject(error);
      }
    );

    return () => api.interceptors.response.eject(unauthorizedInterceptor);
  }, [logout]);

  useEffect(() => {
    const errorInterceptor = api.interceptors.response.use(
      success => {
        return success;
      },
      error => {
        if (!error.response || error.response.status === 500) {
          // async errors aren't normally caught by error boundaries, but using the hook will let it be caught
          setError(() => {
            throw error;
          });
        }
        return Promise.reject(error);
      }
    );
    setErrorHandlingReady(true);
    return () => api.interceptors.response.eject(errorInterceptor);
  }, [setError]);

  useEffect(() => {
    if (user) Heap.associateUser(user);
  }, [user]);

  return (
    <Spin spinning={loading}>
      <ApiContext.Provider value={{ api, logout, setIdpLogoutPath, setJwt, user, setUser }}>
        {errorHandlingReady && children}
      </ApiContext.Provider>
    </Spin>
  );
};

export const API_COMPANIES = 'api/v1/companies';
export const API_CRITERIA_SCHEMAS = 'api/internal/v1/criteria_schemas';
export const API_DISPLAY_SCHEMAS = 'api/internal/v1/display_schemas';
export const API_STATES = 'api/v1/states';
export const API_COUNTIES = 'api/v1/counties';
export const API_USERS = 'api/v1/users';
export const API_APPLICATION_CONFIGURATION = 'api/internal/v1/application_configuration';
export const API_REQUIREMENTS_SEARCH = 'api/v1/requirements/search';
export const API_REQUIREMENTS_REQUEST = 'api/v1/requirements/request';
