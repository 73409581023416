import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import { ApiOutlined, BankOutlined, CheckSquareOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { ApiContext } from './contexts/ApiProvider';
import { ApplicationConfigurationContext } from './contexts/ApplicationConfigurationProvider';

const { Sider } = Layout;

function NavSidebar() {
  let location = useLocation();

  const { user } = useContext(ApiContext);
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);
  const integrationsFlipper = applicationConfiguration?.flippers?.integrations;
  const searchFlipper = applicationConfiguration?.flippers?.search;

  let menuItems = [];

  if (user) {
    if (user.isSuperAdmin()) {
      menuItems.push(
        { key: 'companies', path: '/companies', title: 'Companies', icon: <BankOutlined />, 'data-cy': 'companies' },
        {
          key: 'users',
          path: '/users',
          title: 'Users',
          icon: <TeamOutlined />,
          'data-cy': 'users'
        },
        {
          key: 'super_admins',
          path: '/super_admins',
          title: 'Super Admins',
          icon: <TeamOutlined />,
          'data-cy': 'super-admins'
        },
        {
          key: 'requirements',
          path: '/requirements/states',
          title: 'State Requirements',
          icon: <UnorderedListOutlined />,
          'data-cy': 'requirements'
        }
      );
    } else if (user.isCompanyAdmin()) {
      menuItems.push({
        key: 'companies',
        path: `/companies/${user.company.id}`,
        title: 'Users',
        icon: <TeamOutlined />,
        'data-cy': 'users'
      });
      if (user.company.company_types.length) {
        menuItems.push({
          key: 'requirements',
          path: '/requirements',
          title: 'Requirements',
          icon: <UnorderedListOutlined />,
          'data-cy': 'requirements'
        });
      }
    } else if (user.isCompanyContributor()) {
      menuItems.push({
        key: 'requirements',
        path: '/requirements',
        title: 'Requirements',
        icon: <UnorderedListOutlined />
      });
    }

    if (integrationsFlipper && user.isCompanyAdmin() && user.company.api_enabled) {
      menuItems.push({
        key: 'integrations',
        path: '/integrations',
        title: 'Integrations',
        icon: <ApiOutlined />,
        'data-cy': 'integrations'
      });
    }

    if (searchFlipper) {
      menuItems.push({
        key: 'eligibility',
        path: '/search',
        title: 'View e-Eligibility Exchange',
        icon: <CheckSquareOutlined />,
        'data-cy': 'eligibility-exchange'
      });
    }
  } else {
    menuItems.push({ key: 'login', path: '/login', title: 'Login' });
  }

  return (
    <Sider width={256}>
      <Menu
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        defaultSelectedKeys={['']}
        selectedKeys={[location.pathname.split('/')[1]]}
      >
        {menuItems.map(item => {
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path} data-cy={item['data-cy']}>
                {item.title}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}

export default NavSidebar;
