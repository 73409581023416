import React from 'react';
import { Layout } from 'antd';
import NavSidebar from '../NavSidebar';
import AppFooter from '../AppFooter';

const { Content } = Layout;

const LayoutBasic = ({ children, ...props }) => {
  return (
    <Layout>
      <NavSidebar />
      <Layout style={{ padding: '24px 24px 0 24px' }}>
        <Content className="site-layout-background" style={{ padding: '24px 24px 4px 24px' }}>
          {children}
        </Content>
        <AppFooter {...props} />
      </Layout>
    </Layout>
  );
};

export default LayoutBasic;
