import React, { useContext, useState } from 'react';

import { Breadcrumb, Button, Form, Input, message, Radio, Select, Space, Typography } from 'antd';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import { useTypes } from '../common/Types';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;

function CompanyNew() {
  const history = useHistory();
  const { api } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false); // let the user know errors occured

  const { alphabetizedCompanyTypes, humanizedCompanyTypes, uneditableCompanyTypes } = useTypes();

  const companyTypeOptions = alphabetizedCompanyTypes
    .filter(companyType => !uneditableCompanyTypes.includes(companyType))
    .map(companyType => (
      <Select.Option key={companyType} value={companyType}>
        {humanizedCompanyTypes[companyType]}
      </Select.Option>
    ));

  const setErrors = errors => {
    setLoading(false);
    if (errors['company_users']) {
      errors['user.email'] = 'Email has already been taken.';
    }
    setApiErrors(errors);
    setShowWarning(true);
  };

  const saveCompanyAndUser = async (companyAttrs, userAttrs) => {
    try {
      await api.post(API_COMPANIES, { company: { ...companyAttrs, company_user: { ...userAttrs, role: 'admin' } } });
      setLoading(false);
      message.success('Success - new Company created.');
      history.push('/companies');
    } catch (error) {
      error.response && setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setLoading(true);
    setApiErrors({});
    setShowWarning(null);

    const companyParams = {};
    const userParams = {};
    for (const key in values) {
      if (key.startsWith('user.')) {
        userParams[key.replace('user.', '')] = values[key];
      } else {
        companyParams[key] = values[key];
      }
    }
    await saveCompanyAndUser(companyParams, userParams);
  };

  const onValuesChange = changedValues => {
    // clear the api-side error after a change to reset for FE-side validations
    for (const key in changedValues) {
      if (apiErrors[key]) {
        delete apiErrors[key];
        setApiErrors({ ...apiErrors });
      }
    }
    if (showWarning) {
      setShowWarning(Object.keys(apiErrors).length > 0);
    }
  };

  return (
    <LayoutBasic>
      <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/companies">Companies</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add Company</Breadcrumb.Item>
      </Breadcrumb>

      <Title>Add Company</Title>
      <Space direction="vertical">
        <Text>Add a new Company to give them access to contribute to the MISMO e-Eligibility Exchange.</Text>
        <Form
          form={form}
          initialValues={{ api_enabled: false }}
          layout="vertical"
          size="large"
          onFinish={onFormFinish}
          onFinishFailed={() => {
            setShowWarning(true);
          }} // just draw attention to FE errors
          onValuesChange={onValuesChange}
          requiredMark={false}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item
            name="name"
            label="Company name"
            help={apiErrors['name']}
            validateStatus={apiErrors['name'] && 'error'}
            rules={[{ required: true, message: 'Company name is required.' }]}
          >
            <Input data-cy="company" />
          </Form.Item>
          <Form.Item name="company_types" label="Industry segment(s)">
            <Select mode="multiple" placeholder="Select one or more" data-cy="industry-segment">
              {companyTypeOptions}
            </Select>
          </Form.Item>
          <Form.Item label="Access to API">
            <Space direction="vertical">
              <Text type="secondary">Grant all Company Admins with the ability to create and revoke API keys.</Text>
              <Form.Item name="api_enabled" noStyle>
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label={
              <Space>
                <Text>Company Admin</Text>
                <Text type="warning">(1 Minimum Required)</Text>
              </Space>
            }
          >
            <Text type="secondary">
              Company Admins may add/remove Company Admins, Contributors and edit e-Eligibility data for their given
              organization.
            </Text>
            <br />
            <Space>
              <Form.Item
                name="user.first_name"
                label="First name"
                rules={[{ required: true, message: 'First name is required.' }]}
              >
                <Input data-cy="first-name" />
              </Form.Item>
              <Form.Item
                name="user.last_name"
                label="Last name"
                rules={[{ required: true, message: 'Last name is required.' }]}
              >
                <Input data-cy="last-name" />
              </Form.Item>
            </Space>
            <Form.Item
              name="user.email"
              label="Email address"
              help={apiErrors['user.email']}
              validateStatus={apiErrors['user.email'] && 'error'}
              rules={[{ required: true, type: 'email', message: 'Valid email address is required.' }]}
              normalize={value => value.trim()}
            >
              <Input data-cy="email" />
            </Form.Item>
          </Form.Item>
          {showWarning && <Text type="danger">Could not add Company. See messages above.</Text>}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} data-cy="add-company">
              Add Company
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </LayoutBasic>
  );
}

export default CompanyNew;
