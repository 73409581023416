import React from 'react';
import { renderChildren } from '../common/jsonFormUtil';

const VerticalLayout = props => {
  const { uischema, schema, path, visible } = props;

  // overrides the default vertical layout renderer
  // so we can actually exclude multiple form components with one rule
  // (the vanilla renderer just hides them via styles)
  return visible && <div>{renderChildren(uischema, schema, '', path)}</div>;
};
export default VerticalLayout;
