import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import LayoutBasic from '../layouts/LayoutBasic';
import { formatDateTime } from '../common/DateTime';
import { useTypes } from '../common/Types';

const { Title, Text } = Typography;
const { Search } = Input;

const apiDirections = {
  ascend: 'asc',
  descend: 'desc'
};

function CompanyIndex() {
  const { api } = useContext(ApiContext);

  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [searchParams, setSearchParams] = useState({});

  const [companies, setCompanies] = useState([]);
  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { humanizedCompanyTypes, humanizeTypes } = useTypes();

  const columns = [
    {
      title: 'Company',
      dataIndex: 'fullName',
      defaultSortOrder: 'ascend',
      filters: Object.entries(humanizedCompanyTypes).map(([key, name]) => {
        return { text: name, value: key };
      }),
      key: 'name',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, company) => {
        return (
          <React.Fragment>
            <Link to={`/companies/${company.id}`}>{company.fullName}</Link>
            <br />
            <Text>{humanizeTypes(company.extensions?.eEligibilityRoleTypes)}</Text>
          </React.Fragment>
        );
      }
    },
    {
      title: 'Last Contributed',
      dataIndex: ['extensions', 'eEligibilityRequirementsUpdatedDatetime'],
      key: 'last_contributed_at',
      sortDirections: ['descend', 'ascend', 'descend'], // requires some sort of ordering
      sorter: true,
      render: time => formatDateTime(time)
    },
    {
      title: '# Company Users',
      dataIndex: ['extensions', 'contactsCount'],
      key: 'company_users_count',
      sortDirections: ['descend', 'ascend', 'descend'], // requires some sort of ordering
      sorter: true
    }
  ];

  const onTableChange = (pagination, filters, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current,
      per_page: pagination.pageSize
    };
    if (filters.name) {
      // get the type checkbox filters from the name+types column
      params.company_types = filters.name;
    }
    setTableParams(params);
  };

  const updateSearchParams = query => {
    setTableParams(prevParams => {
      return { ...prevParams, page: 1 }; // reset page
    });
    setSearchParams(
      query.length > 0 ? { name: query } : {} // update the name search param
    );
  };

  useEffect(() => {
    setLoading(true);
    const indexParams = { ...tableParams, ...searchParams };
    api.get(API_COMPANIES, { params: indexParams }).then(response => {
      setCompanies(response.data);
      setPageData({
        current: tableParams.page,
        pageSize: response.headers['per-page'],
        total: response.headers.total
      });
      setLoading(false);
    });
  }, [api, tableParams, searchParams]);

  const onRowClick = record => {
    return {
      onClick: () => {
        history.push(`/companies/${record.id}`);
      }
    };
  };

  return (
    <LayoutBasic>
      <Title>Companies</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12}>
          <Text>
            Companies set up as Contributors for the MISMO e-Eligibility Exchange. Click through to edit details, add
            Company Admins and more.
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Search
              allowClear
              enterButton
              loading={loading && searchParams.name}
              onSearch={updateSearchParams}
              placeholder="Search"
              size="large"
              data-cy="search"
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => history.push('/companies/new')}
              size="large"
              type="primary"
              data-cy="add-Company"
            >
              Add Company
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={companies}
        loading={loading}
        onChange={onTableChange}
        onRow={onRowClick}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
          ...pageData
        }}
        rowKey="id"
        data-cy="companies-tbl"
      />
    </LayoutBasic>
  );
}

export { CompanyIndex as default, apiDirections };
