import { Form, Checkbox } from 'antd';
import React from 'react';
import { convertPathToTag } from '../common/dataTestUtil';

const CheckboxControl = props => {
  const { handleChange, path, label, visible } = props;
  return (
    visible && (
      <Form.Item
        name={path.split('.')}
        valuePropName="checked"
        data-test={convertPathToTag(path)}
        style={{ marginBottom: 0 }}
      >
        <Checkbox onChange={e => handleChange(path, e.target.checked)}>{label}</Checkbox>
      </Form.Item>
    )
  );
};
export default CheckboxControl;
