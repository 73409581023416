import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Alert, Button } from 'antd';
import { ApiContext, baseUrl } from '../contexts/ApiProvider';
import { ApplicationConfigurationContext } from '../contexts/ApplicationConfigurationProvider';

function OIDCLogin({ location, onSubmit }) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const { api, setJwt, setUser } = useContext(ApiContext);
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const query = useQuery();

  const startOIDC = () => {
    setLoading(true);
  };

  const getReturnToFromState = state => {
    const returnTo = state.split('::')[1];
    return returnTo || '/';
  };

  const handleCallback = useCallback(
    (code, state) => {
      setLoading(true);
      api
        .get(applicationConfiguration.oidc_callback_path, {
          params: { code: code, state: state },
          headers: { accept: 'application/json' },
          withCredentials: true
        })
        .then(response => {
          setJwt(response.headers.authorization.match(/Bearer (.*)/)[1]);
          setUser(response.data.user);
          history.replace(getReturnToFromState(state));
        })
        .catch(err => {
          if (err.response) {
            setError(err.response.data.error);
          } else {
            setError('Something went wrong!');
          }

          setLoading(false);
        });
    },
    [api, setJwt, setUser, history, applicationConfiguration]
  );

  useEffect(() => {
    const code = query.get('code');
    const state = query.get('state');
    if (code && state && !loading && !error) {
      handleCallback(code, state);
    }
  }, [query, loading, error, handleCallback]);

  const returnTo = (location.state && location.state.referrer) || '/';
  const oidcUrl = new URL(baseUrl());
  oidcUrl.pathname = applicationConfiguration.oidc_start_path;
  oidcUrl.searchParams.append('return_to', returnTo);

  return (
    <>
      <form action={oidcUrl} method="post" onSubmit={onSubmit}>
        <input type="hidden" name="authenticity_token" value={applicationConfiguration.login_csrf_token} />
        <Button onClick={startOIDC} loading={loading} type="primary" htmlType="submit">
          Sign In
        </Button>
      </form>
      <br />
      <br />
      {error && <Alert type="error" description={error} />}
    </>
  );
}

export default OIDCLogin;
