import React, { useContext, useEffect, useState } from 'react';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { useParams } from 'react-router-dom';
import LayoutWithCard from '../layouts/LayoutWithCard';
import CompanyUsersTable from './CompanyUsersTable';

function Company() {
  const { id: companyId } = useParams();

  const { api } = useContext(ApiContext);

  const [company, setCompany] = useState();
  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}`).then(response => {
      setCompany(response.data);
    });
  }, [api, companyId]);

  return (
    <LayoutWithCard company={company} showFAQLink={true}>
      {company && !company.extensions.eEligibilityRoleTypes.includes('settlement_agency') && (
        <CompanyUsersTable companyId={companyId} />
      )}
    </LayoutWithCard>
  );
}

export default Company;
