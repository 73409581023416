import React, { useContext, useEffect, useState } from 'react';

import { Col, Button, Input, Row, Space, Table, Typography, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ApiContext, API_USERS } from '../contexts/ApiProvider';
import { Link } from 'react-router-dom';
import { apiDirections } from '../company/CompanyIndex';
import LayoutBasic from '../layouts/LayoutBasic';
import { formatDateTime } from '../common/DateTime';

const { Title } = Typography;
const { Search } = Input;
const { confirm } = Modal;

function UsersIndex() {
  const isEditingSelf = user => currentUser.id === user.id;
  const canRemove = user => !isEditingSelf(user) && currentUser.isSuperAdmin();

  const removeUser = async user => {
    setLoading(true);
    await api.delete(`${API_USERS}/${user.id}`);
    reloadTable();
    setLoading(false);
  };

  const confirmDeletion = user => {
    confirm({
      icon: null,
      title: `Delete ${user.first_name} ${user.last_name} (${user.email})?`,
      onOk: () => removeUser(user),
      okText: 'Remove User',
      content: 'Removing this User cannot be undone.'
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, user) => {
        return (
          <React.Fragment>
            {user.first_name} {user.last_name}
          </React.Fragment>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, user) => {
        return <React.Fragment>{user.email}</React.Fragment>;
      }
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: (_, user) => {
        return user.company && <Link to={`/companies/${user.company.id}`}>{user.company.fullName}</Link>;
      }
    },
    {
      title: 'Last Contributed',
      dataIndex: 'last_contributed_at',
      key: 'last_contributed_at',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: time => formatDateTime(time)
    },
    {
      title: 'Last Logged In',
      dataIndex: 'last_logged_in_at',
      key: 'last_logged_in_at',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: time => formatDateTime(time)
    },
    {
      title: '',
      render: (_, user) =>
        canRemove(user) && (
          <Button
            type="default"
            danger={true}
            shape="round"
            icon={<DeleteOutlined />}
            onClick={() => confirmDeletion(user)}
          />
        )
    }
  ];

  const { api, user: currentUser } = useContext(ApiContext);

  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState(true);

  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });

  const onTableChange = (pagination, filters, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current,
      per_page: pagination.pageSize
    };
    setTableParams(params);
  };
  const updateSearchParams = query => {
    setTableParams(prevParams => {
      return { ...prevParams, page: 1 }; // reset page
    });
    setSearchParams(query.length > 0 ? { name_or_email: query } : {});
  };

  const reloadTable = () => {
    setLoading(true);
    const indexParams = { ...tableParams, ...searchParams };
    api.get(API_USERS, { params: indexParams }).then(response => {
      setUsers(response.data);
      setPageData({
        current: tableParams.page,
        pageSize: response.headers['per-page'],
        total: response.headers.total
      });
      setLoading(false);
    });
  };

  useEffect(reloadTable, [api, tableParams, searchParams]);

  return (
    <LayoutBasic>
      <Title>Users</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12} />
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Search
              allowClear
              enterButton
              loading={loading && searchParams.name}
              onSearch={updateSearchParams}
              placeholder="Search"
              size="large"
              data-cy="search"
            />
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={users}
        loading={loading}
        onChange={onTableChange}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
          ...pageData
        }}
        rowKey="id"
        data-cy="users-tbl"
      />
    </LayoutBasic>
  );
}

export default UsersIndex;
