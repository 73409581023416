const getHeapScript = id => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`;

const Heap = {
  init: heap_id => {
    const script = document.createElement('script');

    script.innerHTML = getHeapScript(heap_id);
    script.id = 'react-heap';
    script.async = true;

    document.body.appendChild(script);
  },

  associateUser: userData => {
    if (!window.heap) return;
    if (!userData) return;

    window.heap.identify(userData.id);
    window.heap.addUserProperties({
      email: userData.email,
      first_name: userData.first_name,
      last_name: userData.last_name,
      role: userData.role,
      company_id: userData.company?.id,
      company_name: userData.company?.name
    });
  }
};

export default Heap;
