import React, { useContext, useEffect, useState } from 'react';

import { Breadcrumb, Button, Form, Modal, Space, Spin, Typography } from 'antd';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useHistory, useParams } from 'react-router-dom';
import { extractParams, generateOnValuesChange, CompanyUserFormFields } from './CompanyUserFormCommon';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;
const { confirm } = Modal;

function CompanyUserEdit() {
  const { company_id: companyId, id: companyUserId } = useParams();

  const history = useHistory();
  const { api, user: currentUser } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [userLoading, setUserLoading] = useState(true);
  const [companyLoading, setCompanyLoading] = useState(true);
  const loading = userLoading || companyLoading;

  const [apiErrors, setApiErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false); // let the user know errors occured
  const [company, setCompany] = useState({});
  const [companyUser, setCompanyUser] = useState({});
  const [isContributor, setIsContributor] = useState(false);

  const isEditingSelf = currentUser.company_user?.id === companyUserId;
  const canRemove = !isEditingSelf && (currentUser.isSuperAdmin() || currentUser.isCompanyAdmin());

  const setErrors = errors => {
    setUserLoading(false);
    setApiErrors(errors);
    setShowWarning(true);
  };

  const saveUser = async attributes => {
    try {
      await api.put(`${API_COMPANIES}/${companyId}/company_users/${companyUserId}`, attributes);
      return true;
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setUserLoading(true);
    setApiErrors({});
    setShowWarning(null);

    const params = extractParams(values);
    if (params.role === 'admin' && companyUser.role === 'contributor') {
      params.contributor_types = []; // clear if we're making them an admin
    }

    if (await saveUser(params)) {
      history.push(`/companies/${companyId}`);
    }
  };

  const onValuesChange = generateOnValuesChange(
    apiErrors,
    setApiErrors,
    showWarning,
    setShowWarning,
    setIsContributor,
    form,
    company
  );

  const removeCompanyUser = async () => {
    setUserLoading(true);
    await api.delete(`${API_COMPANIES}/${companyId}/company_users/${companyUserId}`);
    history.push(`/companies/${companyId}`);
  };

  const confirmDeletion = () => {
    confirm({
      icon: null,
      title: 'Are you sure?',
      onOk: removeCompanyUser,
      okText: 'Remove User',
      content: 'Removing this User cannot be undone.'
    });
  };

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}`).then(response => {
      setCompany(response.data);
      setCompanyLoading(false);
    });
  }, [api, companyId]);

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}/company_users/${companyUserId}`).then(response => {
      const apiData = response.data;
      setCompanyUser(apiData);
      setIsContributor(apiData.role === 'contributor');

      const formData = { ...apiData };
      for (const key in apiData.user) {
        formData[`user.${key}`] = apiData.user[key];
      }
      form.setFieldsValue(formData);
      setUserLoading(false);
    });
  }, [api, companyId, companyUserId, form]);

  return (
    <LayoutBasic showFAQLink={true}>
      <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={`/companies/${companyId}`}>{company.fullName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>
          {companyUser.user?.first_name} {companyUser.user?.last_name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading}>
        <Title>Edit User</Title>
        <Space direction="vertical">
          <Form
            form={form}
            initialValues={{ role: 'admin' }}
            layout="vertical"
            size="large"
            onFinish={onFormFinish}
            onFinishFailed={() => {
              setShowWarning(true);
            }} // just draw attention to FE errors
            onValuesChange={onValuesChange}
            requiredMark={false}
            style={{ maxWidth: 324 }}
          >
            <CompanyUserFormFields
              company={company}
              isContributor={isContributor}
              apiErrors={apiErrors}
              disableRoleSelect={isEditingSelf}
            />
            {showWarning && <Text type="danger">Could not save User. See messages above.</Text>}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={loading} data-cy="save">
                  Save Changes
                </Button>
                {canRemove && (
                  <Button type="link" onClick={confirmDeletion} data-cy="remove">
                    Remove User
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </LayoutBasic>
  );
}

export default CompanyUserEdit;
