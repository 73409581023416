import React, { useState, createContext, useContext, useEffect } from 'react';
import { ApiContext, API_APPLICATION_CONFIGURATION } from './ApiProvider';
import Heap from '../Heap';

export const ApplicationConfigurationContext = createContext({});

export const ApplicationConfigurationProvider = ({ children }) => {
  const [applicationConfiguration, setApplicationConfiguration] = useState({});
  const [loading, setLoading] = useState(true);

  const { api, setIdpLogoutPath } = useContext(ApiContext);

  useEffect(() => {
    setLoading(true);
    api.get(API_APPLICATION_CONFIGURATION, { withCredentials: true }).then(response => {
      if (response.data.oidc_logout_path) {
        setIdpLogoutPath(response.data.oidc_logout_path);
      }
      if (response.data.heap_id) {
        Heap.init(response.data.heap_id);
      }
      setApplicationConfiguration(response.data);
      setLoading(false);
    });
  }, [api, setApplicationConfiguration, setIdpLogoutPath]);

  if (loading) {
    return null;
  } else {
    return (
      <ApplicationConfigurationContext.Provider value={{ applicationConfiguration }}>
        {children}
      </ApplicationConfigurationContext.Provider>
    );
  }
};
