import React, { useContext, useEffect, useState } from 'react';

import { Button, Col, Input, Row, Space, Table, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ApiContext, API_USERS } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import { apiDirections } from '../company/CompanyIndex';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;
const { Search } = Input;

function SuperAdminIndex() {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, superAdmin) => {
        return (
          <React.Fragment>
            <Link to={`/super_admins/${superAdmin.id}`}>
              {superAdmin.first_name} {superAdmin.last_name}
            </Link>
          </React.Fragment>
        );
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
      sorter: true,
      render: (_, superAdmin) => {
        return <React.Fragment>{superAdmin.email}</React.Fragment>;
      }
    }
  ];

  const history = useHistory();
  const { api } = useContext(ApiContext);

  const [superAdmins, setSuperAdmins] = useState([]);
  const [searchParams, setSearchParams] = useState({});
  const [loading, setLoading] = useState(true);

  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });

  const onTableChange = (pagination, filters, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current
    };
    setTableParams(params);
  };
  const updateSearchParams = query => {
    setTableParams(prevParams => {
      return { ...prevParams, page: 1 }; // reset page
    });
    setSearchParams(query.length > 0 ? { name_or_email: query } : {});
  };

  useEffect(() => {
    setLoading(true);
    const indexParams = { ...tableParams, ...searchParams };
    api.get(`${API_USERS}?super_admins_only=true`, { params: indexParams }).then(response => {
      setSuperAdmins(response.data);
      setPageData({
        current: tableParams.page,
        pageSize: response.headers['per-page'],
        total: response.headers.total
      });
      setLoading(false);
    });
  }, [api, tableParams, searchParams]);

  return (
    <LayoutBasic>
      <Title>Super Admins</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12}>
          <Text>
            Manage Super Admins in the MISMO e-Eligibility Exchange. Super Admins can manage Super Admins, Companies,
            Company users, and state and county requirements.
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Search
              allowClear
              enterButton
              loading={loading && searchParams.name}
              onSearch={updateSearchParams}
              placeholder="Search"
              size="large"
              data-cy="search"
            />
            <Button
              icon={<PlusOutlined />}
              onClick={() => history.push('/super_admins/new')}
              size="large"
              type="primary"
              data-cy="add-superadmin"
            >
              Add Super Admin
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={superAdmins}
        loading={loading}
        onChange={onTableChange}
        pagination={{ position: ['bottomCenter'], showSizeChanger: false, ...pageData }}
        rowKey="id"
        data-cy="superadmins-tbl"
      />
    </LayoutBasic>
  );
}

export default SuperAdminIndex;
