import React from 'react';
import { JsonForms, withJsonFormsControlProps, withJsonFormsLayoutProps } from '@jsonforms/react';
import { schemaTypeIs } from '@jsonforms/core';
import { vanillaCells } from '@jsonforms/vanilla-renderers';
import { verticalLayoutTester } from '@jsonforms/vanilla-renderers';

import { useTypes } from '../common/Types';
import CheckTreeControl from './CheckTreeControl';
import { renderChildren } from '../common/jsonFormUtil';
import { Space, Typography } from 'antd';
import AdditionalNotes from './AdditionalNotes';
import moment from 'moment';

const { Text } = Typography;

const CardLayout = props => {
  const { uischema, schema, path, visible } = props;

  return (
    visible && (
      <Space direction="vertical" size="middle">
        {renderChildren(uischema, schema, '', path)}
      </Space>
    )
  );
};

const renderers = [
  { tester: verticalLayoutTester, renderer: withJsonFormsLayoutProps(CardLayout) },
  { tester: schemaTypeIs('boolean'), renderer: withJsonFormsControlProps(CheckTreeControl) } // renders root check boxes
];

function RequirementsCard(props) {
  const { type, name, requirements, uiSchema, dataSchema } = props;
  const hasUiSchema = uiSchema && Object.keys(uiSchema).length > 0; // TODO can remove when all types are defined
  const { humanizedRequirementTypes, requirementRootProperties } = useTypes();
  const rootObject = requirements?.[requirementRootProperties[type]];

  const additionalText =
    rootObject?.['AdditionalRequirements'] &&
    rootObject['AdditionalRequirements'].length > 0 &&
    rootObject['AdditionalRequirements'];

  const lastUpdatedTime = rootObject?.createdDatetime && moment(rootObject.createdDatetime);

  return (
    <div className="search-results-card">
      <h5 className="search-results-type">{humanizedRequirementTypes[type]}</h5>
      <h4 className="search-results-name">{name}</h4>
      {hasUiSchema && (
        <JsonForms
          data={requirements}
          schema={dataSchema}
          uischema={uiSchema}
          renderers={renderers}
          cells={vanillaCells}
        />
      )}
      <br />
      {additionalText && (
        <Space direction="vertical" size="small" className="search-notes" data-test={'Additional_Requirements'}>
          <Text className="search-notes-subtitle">Additional notes and requirements</Text>
          <AdditionalNotes text={additionalText} />
        </Space>
      )}
      <div className="search-results-last-updated">
        {lastUpdatedTime && <Typography.Text>Last Updated {lastUpdatedTime.format('MM/DD/YYYY')}</Typography.Text>}
      </div>
    </div>
  );
}

export default RequirementsCard;
