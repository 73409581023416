import './App.less';
import React, { useContext } from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ApiContext } from './contexts/ApiProvider';
import AppHeader from './AppHeader';
import ApiKeyIndex from './integrations/ApiKeyIndex';
import Company from './company/Company';
import CompanyEdit from './company/CompanyEdit';
import CompanyNew from './company/CompanyNew';
import CompanyIndex from './company/CompanyIndex';
import CompanyUserEdit from './companyUser/CompanyUserEdit';
import CompanyUserNew from './companyUser/CompanyUserNew';
import ContributorFAQ from './faq/ContributorFAQ';
import ContributorTerms from './terms/ContributorTerms';
import StateRequirementsForm from './requirements/StateRequirementsForm';
import CompanyRequirementsForm from './requirements/CompanyRequirementsForm';
import RequirementsIndex from './requirements/RequirementsIndex';
import RootRedirector from './RootRedirector';
import Login from './login/Login';
import Search from './search/Search';
import StateIndex from './state/StateIndex';
import SuperAdminIndex from './superAdmin/SuperAdminIndex';
import SuperAdminNew from './superAdmin/SuperAdminNew';
import SuperAdminEdit from './superAdmin/SuperAdminEdit';
import UsersIndex from './users/UsersIndex';
import ViewerTerms from './terms/ViewerTerms';
import Pendo from './Pendo/Pendo';

function AuthRoute({ roles, ...props }) {
  // redirect to login if not logged in as required role
  const { user } = useContext(ApiContext);
  let location = useLocation();

  const canAccess = user && (!roles || roles.includes(user?.role));
  const termsAcceptPending =
    user && ['contributor', 'admin'].includes(user.role) && !user.agreed_to_contributor_terms_at;
  const viewerTermsAcceptPending = user && !user.agreed_to_viewer_terms_at;

  if (canAccess && termsAcceptPending) {
    return (
      <Redirect
        from={props.path}
        to="/contributor_terms" // redirect to terms and conditions if they haven't been accepted
      />
    );
  } else if (viewerTermsAcceptPending && props.path === '/search') {
    return (
      <Redirect
        from={props.path}
        to="/viewer_terms" // redirect to viewer terms and conditions if they haven't been accepted
      />
    );
  } else if (canAccess) {
    return <Route {...props} />;
  } else if (user) {
    return (
      <Redirect
        from={props.path}
        to="/" // redirect to dashboard for now if already logged in
      />
    );
  } else {
    return (
      <Redirect
        from={props.path}
        to={{
          pathname: '/login',
          state: { referrer: location.pathname }
        }}
      />
    );
  }
}

function ContributorRoute(props) {
  return <AuthRoute {...props} roles={['contributor', 'admin', 'super_admin']} />;
}

function CompanyAdminRoute(props) {
  return <AuthRoute {...props} roles={['admin', 'super_admin']} />;
}

function SuperAdminRoute(props) {
  return <AuthRoute {...props} roles={['super_admin']} />;
}

function TestRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        test dashboard
      </Route>
      <Route path="/login">test login</Route>
      <Route path="/contributor_terms">test terms</Route>
      <Route path="/viewer_terms">test viewer terms</Route>
      <AuthRoute path="/testprotected">test protected page</AuthRoute>
      <SuperAdminRoute path="/testsuperadmin">test super admin page</SuperAdminRoute>
      <CompanyAdminRoute path="/testcompanyadmin">test company admin page</CompanyAdminRoute>
      <ContributorRoute path="/testcontributor">test contributor page</ContributorRoute>
    </Switch>
  );
}

function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={RootRedirector} />
      <Route path="/login" component={Login} />
      <Route path="/contributor_terms" component={ContributorTerms} />
      <Route path="/viewer_terms" component={ViewerTerms} />
      <AuthRoute path="/search" component={Search} />
      <CompanyAdminRoute path="/companies/:company_id/users/new" component={CompanyUserNew} />
      <CompanyAdminRoute path="/companies/:company_id/users/:id" component={CompanyUserEdit} />
      <SuperAdminRoute path="/companies/new" component={CompanyNew} />
      <SuperAdminRoute path="/companies/:id/edit" component={CompanyEdit} />
      <CompanyAdminRoute path="/companies/:id" component={Company} />
      <SuperAdminRoute path="/companies" component={CompanyIndex} />
      <CompanyAdminRoute path="/integrations" component={ApiKeyIndex} />
      <SuperAdminRoute path="/requirements/states/:id" component={StateRequirementsForm} />
      <SuperAdminRoute path="/requirements/states" component={StateIndex} />
      <ContributorRoute path="/requirements/:type" component={CompanyRequirementsForm} />
      <ContributorRoute path="/requirements" component={RequirementsIndex} />
      <ContributorRoute path="/contributor_faq" component={ContributorFAQ} />
      <SuperAdminRoute path="/super_admins/new" component={SuperAdminNew} />
      <SuperAdminRoute path="/super_admins/:id" component={SuperAdminEdit} />
      <SuperAdminRoute path="/super_admins" component={SuperAdminIndex} />
      <SuperAdminRoute path="/users" component={UsersIndex} />
      <Redirect to="/" />
    </Switch>
  );
}

function App() {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <AppHeader />
        <Layout>{process.env.NODE_ENV === 'test' ? <TestRoutes /> : <AppRoutes />}</Layout>
      </Layout>
      <Pendo />
    </>
  );
}

export default App;
