import React from 'react';
import { Typography } from 'antd';
import { API_USERS } from '../contexts/ApiProvider';
import Terms from './Terms';

function ViewerTerms() {
  const { Text, Title } = Typography;

  return (
    <Terms postUrl={`${API_USERS}/viewer_terms`} redirectUrl={'/search'} termsField={'agreed_to_viewer_terms_at'}>
      <Title className="document-title" level={1}>
        MISMO e-Eligibility Exchange Terms of Use
      </Title>
      <Text>
        The resources to which you will have access by using this MISMO e-Eligibility Exchange provided by the Mortgage
        Industry Standards Maintenance Organization, Inc. ("MISMO"), a subsidiary of the Mortgage Bankers Association
        ("MBA") (the "Exchange"), including audio and visual information, data, documents, text, services and software
        ("Content"), are provided to you subject to the following Terms of Use. MISMO reserves the right to update the
        Terms of Use at any time without notice to you. The most current version of the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={
            'https://www.mismo.org/standards-resources/digital-mortgage-resource-center/e-eligibility/e-eligibility-exchange-terms-of-use'
          }
        >
          Terms of Use
        </a>{' '}
        can be reviewed by clicking on the "Terms of Use" hypertext link. You periodically should check back and makes
        sure that you have reviewed the most current version of the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={
            'https://www.mismo.org/standards-resources/digital-mortgage-resource-center/e-eligibility/e-eligibility-exchange-terms-of-use'
          }
        >
          Terms of Use
        </a>
        .
        <br />
        <br />
      </Text>
      <ol style={{ paddingLeft: 26 }}>
        <li>
          <Text>
            <u>Acceptance of Terms</u>.<br />
            By using the Exchange to access and use the Content, you acknowledge and agree that you have read,
            understand and agree to be bound by the provisions of these Terms of Use exactly as if the Terms of Use was
            printed on paper and signed by you. If you do not agree to be bound by the Terms of Use in its entirety, or
            do not agree with any later modified Terms of Use, then you must immediately stop using the Exchange. If you
            access or use the Content in the course of your employment or business, you enter into the Terms of Use both
            on your own behalf as an individual and in your capacity as an employee, officer, agent, partner, or other
            agent or representative of the organization that you represent, and references in these Terms of Use to
            "you" shall mean both you as the individual user of the Exchange and you in your capacity as a
            representative of your organization.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Use Licenses</u>. Access to the Content is provided to you, in each instance, under one of two licenses,
            an End-User License and/or a Distributor License. You agree to abide by the terms of the applicable license,
            as set forth below. The license applicable in any usage instance is determined by the usage that you make of
            the Exchange.
            <br />
            <br />
          </Text>
          <Text>
            &emsp;&emsp;<u>End-User License.</u> If you are an individual or organization who uses the Exchange for its
            own internal business purposes within the confines of the organization, then your use is subject to this
            license.
          </Text>
          <ol type="a" style={{ paddingLeft: 22 }}>
            <li>
              <Text>
                MISMO hereby grants to you a non-exclusive license to access the MISMO e-Eligibility Exchange to obtain
                MISMO e-Eligibility Exchange data (state, county, counterparties, etc.) which you may then use for
                internal business purposes only, subject to these Terms of Use.
              </Text>
            </li>
            <li>
              <Text>
                Your access to the MISMO e-Eligibility Exchange, including the MISMO e-Eligibility Exchange data, and
                the right to use the data for internal business purposes, does not convey to you any right to use the
                MISMO e-Eligibility Exchange for any other purpose or to sell the MISMO e-Eligibility Exchange data to
                third parties. The Distributor License below will govern the provision of MISMO e-Eligibility Exchange
                data to third parties via API integration.
              </Text>
            </li>
            <li>
              <Text>
                You agree to the terms of this end-user license with your first use of the MISMO e-Eligibility Exchange
                on your or your company’s behalf.
              </Text>
            </li>
            <li>
              <Text>
                MISMO reserves the right to modify this MISMO e-Eligibility Exchange end-user license at any time.
              </Text>
            </li>
          </ol>
          <br />
          <Text>
            &emsp;&emsp;<u>Distributor License.</u> If you are an individual or organization who will use the Exchange
            (via API or other authorized means) to share eligibility requirements data with third parties, then your use
            is subject to this license. Data sharing with third parties other than through API or other means expressly
            authorized by MISMO is strictly prohibited.
          </Text>
          <ol type="a" style={{ paddingLeft: 22 }}>
            <li>
              <Text>
                MISMO hereby grants to you a non-exclusive license to integrate with the MISMO e-Eligibility Exchange
                API in order to obtain MISMO e-Eligibility Exchange data (state, county, counterparties, etc.) for named
                third parties who may then use such data for internal business purposes only, subject to these Terms of
                Use.
              </Text>
            </li>
            <li>
              <Text>
                Each time you access the MISMO e-Eligibility Exchange via the API on behalf of a third-party, you will
                provide MISMO with the name of the third-party on behalf of which the data query is being made.
              </Text>
            </li>
            <li>
              <Text>
                You will be responsible for notifying each third party for which you submit a data query to the MISMO
                e-Eligibility Exchange via the API that (a) their use of the MISMO e-Eligibility Exchange data returned
                to them is for internal business purposes only, and (b) their name is being provided to the MISMO
                e-Eligibility Exchange.
              </Text>
            </li>
            <li>
              <Text>
                Except as expressly set forth in this distributor license, your access to the MISMO e-Eligibility
                Exchange, including the MISMO e-Eligibility Exchange data, and the right to provide the data to third
                parties for their internal business purposes, does not convey to you any right to use the MISMO
                e-Eligibility Exchange for any other purpose. The end-user license above will govern any internal use
                that your company wants to make of the MISMO e-Eligibility Exchange on its own behalf (rather than on
                behalf of a third party).
              </Text>
            </li>
            <li>
              <Text>
                You agree to the terms of this distributor license with your first use of the MISMO e-Eligibility
                Exchange on behalf of a third party.
              </Text>
            </li>
            <li>
              <Text>
                MISMO reserves the right to modify this MISMO e-Eligibility Exchange distributor license at any time.
              </Text>
            </li>
          </ol>
          <br />
        </li>
        <li>
          <Text>
            <u>Privacy and Protection of Personal Information</u>. See the <b>Privacy Policy</b>{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://www.mismo.org/about-MISMO/policies-and-procedures/mismo-privacy-policy'}
            >
              https://www.mismo.org/about-MISMO/policies-and-procedures/mismo-privacy-policy
            </a>{' '}
            for information regarding the collection and use of your personal information.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Content Disclaimers</u>. The information and services offered on the Exchange are provided with the
            understanding that MISMO, MBA, and any Exchange content suppliers are not engaged in rendering legal or
            other professional services or advice. Reference to any specific commercial product, process or service does
            not constitute or imply any endorsement, recommendation or favoring by MISMO or MBA. MISMO, MBA and all
            third-party suppliers to the Exchange (referred to in this Disclaimers section collectively as MISMO) assume
            no responsibility for any consequence relating directly or indirectly to any action or inaction that you
            take based on the information, services or other material on this Exchange. While MISMO strives to keep the
            information on the Exchange accurate, complete, and up-to-date, MISMO cannot guarantee, and will not be
            responsible for any damage or loss related to the accuracy, completeness, or timeliness of the Content.
            MISMO MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE CONTENT OF THIS APPLICATION. THE CONTENT IS
            PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND AND IS FOR REFERENCE PURPOSES ONLY. MISMO HEREBY DISCLAIMS ALL
            WARRANTIES AND CONDITIONS WITH REGARD TO THE CONTENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING
            (WITHOUT LIMITATION) THE IMPLIED WARRANTIES OF MERCHANTABILIY AND FITNESS FOR A PARTICULAR PURPOSE; OF
            CONDITION, QUALITY, DURABILITY, SUITABILITY, AND TITLE; NON-INFRINGEMENT OF THIRD-PARTY RIGHTS; AND IMPLIED
            WARRANTIES ARISING FROM A COURSE OF DEALING OR A COURSE OF PERFORMANCE. WITHOUT LIMITING THE GENERALITY OF
            THE FOREGOING, MISMO MAKES NO REPRESENTATION THAT THE APPLICATION, OR THE RESULTS OBTAINED THEREFROM, WILL
            BE ERROR-FREE, OR THAT ACCESS THERETO WILL BE UNINTERRUPTED, OR THAT NO CONTAMINATION OF SYSTEMS WILL RESULT
            FROM USE OF THE APPLICATION. IN NO EVENT SHALL MISMO BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL
            OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THE APPLICATION OR WITH THE
            DELAY OR INABILITY TO USE THE APPLICATION, OR FOR ANY INFORMATION, PRODUCTS, AND SERVICES OBTAINED THROUGH
            THE APPLICATION, OR OTHERWISE ARISING OUT OF THE USE OF THE APPLICATION, WHETHER BASED ON CONTRACT, TORT,
            STRICT LIABILITY, OR OTHERWISE, EVEN IF MISMO HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. EXCEPT AS
            REQUIRED BY ANY APPLICABLE MANDATORY LAW, IN NO EVENT WILL THE AGGREGATE LIABILITY OF MISMO, WITH RESPECT TO
            ALL CLAIMS ARISING IN CONNECTION WITH THIS AGREEMENT, THE APPLICATION, ALL CONTENT, AND ALL SERVICES, EXCEED
            AN AMOUNT EQUAL TO ONE HUNDRED UNITED STATES DOLLARS. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT
            APPLY TO YOU. You acknowledge and agree that nothing in this Agreement constitutes an undertaking by MISMO
            to provide any Content or to continue providing any Content, or any aspect or portion of the Content, in the
            future. MISMO may, in its sole discretion, update, enhance, add to, or make any other changes to any aspect
            of the Exchange or remove Content without notice. All updates, enhancements, additions, and other changes to
            the Exchange are subject to the Terms of Use.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Doing Business on the Internet</u>. You acknowledge and agree that electronic communications and
            databases are subject to errors, tampering and break-ins and that, notwithstanding the efforts of MISMO to
            implement reasonable security precautions, MISMO does not guarantee or warrant that such events will not
            take place. You agree to follow all access and security procedures provided from time to time by MISMO, and
            you will not attempt, nor will you aid or abet any other person in any attempt, to circumvent or otherwise
            interfere in any way with any security precautions or measures of MISMO.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>No Unlawful or Prohibited Use</u>. As a condition of your use of the Exchange, you will not use the
            Content or access the Exchange for any purpose that is harmful, abusive, derogatory, defamatory, in
            violation of any confidentiality obligation, invasive of any third party's rights, unlawful, disruptive to
            the Exchange, or otherwise prohibited by these terms, conditions, and notices in the Terms of Use and the
            Privacy Policy. You may not obtain or attempt to obtain any materials or information through any means not
            intentionally made available through the Exchange. Without limiting the generality of the foregoing, you
            will not directly or indirectly (a) reverse engineer, decompile, disassemble, translate, modify or attempt
            to replicate the Exchange, (b) attempt to access, create or derive the source code for the Exchange, (c)
            develop, support or use software, devices, scripts, robots or any other means or processes (including
            crawlers, browser plugins and add-ons or any other technology) to scrape the Exchange, or (d) override any
            security feature or bypass or circumvent any access controls or use limits of the Exchange. MISMO reserves
            the right to terminate your use of the Exchange, in its sole discretion, with or without notice, if MISMO
            reasonably believes that your conduct fails to conform to the Terms of Use or to comply with the terms of
            the applicable license governing Your use.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Links to Third Party Sites</u>. Links on the Exchange may let you leave the Exchange. Such links are
            provided solely as pointers to services or information on topics that may be useful to visitors to the
            Exchange, and MISMO has no control over the content on such linked sites (or any link contained in a linked
            site). If you choose to link to a site or content not controlled by MISMO, MISMO makes no warranties, either
            express or implied, concerning the content of such site, including the accuracy, completeness, reliability
            or suitability thereof for any particular purpose, nor does MISMO warrant that such site or content is free
            from any claims of copyright, trademark or other infringement of the rights of third parties or that such
            site or content is devoid of viruses or other contamination. Links to sites not operated by MISMO do not
            imply any endorsement of or responsibility for the opinions, ideas, products, information or services
            offered at such sites, or any representation regarding the content at such sites. MISMO also advises you to
            examine the terms of use and privacy statements of any third-party web site.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Proprietary Rights</u>. Except for reference materials in the public domain, e.g., statutes, regulations,
            and governmental reports, all Exchange Content (as well as the organization and layout of the Exchange) are
            owned and copyrighted by MISMO or its suppliers. © Copyright 2022 Mortgage Industry Standards Maintenance
            Organization, Inc. All rights reserved. No reproduction, distribution, or transmission of the copyrighted
            Content is permitted without the written permission of MISMO.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Arbitration</u>. By accessing the Exchange and using the information therein available, you agree with
            MISMO, MBA, its service providers, their affiliates, parents, subsidiaries and any other content provider or
            offeror of goods or services on this site or through any other associated activity, that any claim or
            dispute ("Claim"), you may have against or with any of these persons or entities, whether related to the
            Terms of Use or the Privacy Policy, the purchase of any product or service or to the Content, including the
            enforceability of this arbitration clause, and whether under a statute, in contract, tort, or otherwise and
            whether for money damages, or declaratory or equitable relief, shall be resolved by binding arbitration
            before a single arbitrator under the Commercial Arbitration Rules of the American Arbitration Association.
            Any arbitration hearing at which you appear will take place within the District of Columbia, and judgment on
            the award rendered by the arbitrator may be entered in any court having jurisdiction thereof. The arbitrator
            shall have no power or authority to make any award that provides for punitive or exemplary damages. Each
            party shall bear its own costs in connection with any arbitration proceedings. The parties shall equally
            share the fees of the arbitration and the arbitrator. This arbitration agreement is made pursuant to a
            transaction involving interstate commerce, and shall be governed by the Federal Arbitration Act, 9 U.S.C. §§
            1-16 ("FAA"). The arbitrator shall follow applicable substantive law, to the extent consistent with the FAA,
            and applicable statutes of limitations and shall honor any claims or privilege recognized by law. If any
            party requests, the arbitrator shall write an opinion containing the reasons for the award. NO CLAIM
            SUBMITTED TO ARBITRATION IS HEARD BY A JURY, AND NO CLAIM MAY BE BROUGHT AS A CLASS ACTION OR AS A PRIVATE
            ATTORNEY GENERAL. You do not have the right to act as a class representative or participate as a member of a
            class of claimants with respect to any Claim. This Arbitration Section applies to all Claims now in
            existence or that may arise and shall survive the termination of any dealings with us. For the purposes of
            this Arbitration Section, "we" and "us" means MISMO, its parent, subsidiaries, affiliates, licensees or
            sublicensees, predecessors, successors, assigns and all of their officers, directors, employees, agents and
            assigns or any and all of them. Additionally, "we" or "us" shall mean any third party providing benefits,
            services, or products in connection with the Exchange and all of their officers, directors, employees and
            agents if, and only if, such a third party is named by you as a co-defendant in any Claim you assert against
            us. If any part of this Arbitration Section is found to be invalid or unenforceable under any law or statute
            consistent with the FAA, the remainder of this Arbitration Section shall be enforceable without regard to
            such invalidity or unenforceability. THE RESULT OF THIS ARBITRATION SECTION IS THAT CLAIMS CANNOT BE
            LITIGATED IN COURT, INCLUDING SOME CLAIMS THAT COULD HAVE BEEN TRIED BEFORE A JURY, AS CLASS ACTIONS OR AS
            PRIVATE ATTORNEY GENERAL ACTIONS.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Trademarks</u>. Except as indicated, MISMO owns all trademarks, service marks, certification marks, and
            logos featured on this site, including the terms "MISMO," “MISMO e-Eligibility Exchange,” and the "Mortgage
            Industry Standards Maintenance Organization." Use of these marks without the express written permission of
            MISMO is expressly prohibited. The name “Snapdocs” in the tagline, “MISMO e-Eligibility Exchange, Powered by
            Snapdocs,” is owned by Snapdocs, Inc. and may not be used without the express permission of Snapdocs, Inc.
            You will not remove, delete, alter or obscure any trademarks or any copyright, trademark, patent or other
            intellectual property or proprietary rights notices provided on or in connection with the Exchange.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Force Majeure</u>. Notwithstanding any other term or condition of the Terms of Use, MISMO shall not be
            obligated to perform or observe its obligations undertaken in the Terms of Use if prevented or hindered from
            doing so by circumstances beyond its control.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Indemnification</u>. You will indemnify and hold harmless MISMO and Snapdocs, Inc. and their respective
            employees, officers, directors, providers and other agents from any and all claims and losses imposed on,
            incurred by or asserted as a result of or related to: (a) any non-compliance by you with the terms and
            conditions hereof, including the applicable license; or (b) any third-party actions related to your use of
            the Exchange and the Content, whether authorized or unauthorized under the Terms of Use.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Term and Termination</u>. Upon notice to you, MISMO may terminate the Terms of Use and your access to the
            Exchange, at any time and for any reason including, but not limited to, your breach of any term of the Terms
            of Use.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Headings</u>. Section headings are included for convenience only and are not to be used to construe or
            interpret the Terms of Use
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Waiver and Severability</u>. No failure on the part of MISMO or you to exercise and no delay in
            exercising any right, power, or privilege under the Terms of Use shall operate as a waiver thereof. If the
            exercise of any right, power or privilege shall to any extent be held invalid or unenforceable, the
            remainder of the Terms of Use shall not be affected thereby. Each term and provision of the Terms of Use
            shall be valid and enforceable to the fullest extent permitted by law.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Governing Law</u>. The Terms of Use shall be deemed to have been made in the United States in the
            District of Columbia and shall be construed and enforced in accordance with, and the validity and
            performance hereof shall be governed by, the laws of the District of Columbia, without reference to
            principles of conflicts of laws thereof.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Jurisdiction and Venue</u>. In the event of any court litigation arising under or related to the Terms of
            Use, the Exchange and the Content, MISMO and you irrevocably consent to the exclusive jurisdiction of, and
            venue in, the courts, local and federal, of the District of Columbia and submit to the personal jurisdiction
            of the courts located the District of Columbia.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Entire Agreement</u>. The Terms of Use constitutes the entire agreement between the parties with respect
            to the subject matter hereof, and supersedes all prior negotiations, communications, writings, and
            understandings. MISMO may modify the Terms of Use from time to time. If you do not agree to the
            modifications, stop using the Exchange and accessing all Content. Your continued use of the Exchange after
            the effective date of any modifications means that you agree to the modifications. If any provision of the
            Terms of Use is invalid or unenforceable, the remaining provisions will continue to be valid and
            enforceable.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Conflict</u>. In the case of any conflict between the Terms of Use and any additional terms and
            conditions to which you may agree by accessing or using specific Content, the additional terms and
            conditions relating to the specific Content will control in connection with your access to or use of that
            Content.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Notices</u>. All notices and other communications under the Terms of Use shall be in writing and shall be
            directed to MISMO at the address below or to you at the email address or postal address provided by you, if
            any. Notice shall be deemed to have been duly given upon actual receipt by a party, or upon constructive
            receipt if sent by certified mail, postage pre-paid, return receipt requested, at such address or any other
            address, as any party hereto shall hereafter specify by Notice to the other party hereto.
            <br />
            <br />
          </Text>
        </li>
        <li>
          <Text>
            <u>Address and Phone Number of MISMO</u>.<br />
            MISMO
            <br />
            1919 M Street NW, 5th floor
            <br />
            Washington, DC 20036
            <br />
            (202) 557-2700
            <br />
          </Text>
        </li>
      </ol>
    </Terms>
  );
}

export default ViewerTerms;
