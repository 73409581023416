import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import RequirementsCard from './RequirementsCard';
import { useTypes } from '../common/Types';
import { API_DISPLAY_SCHEMAS, ApiContext } from '../contexts/ApiProvider';

const getRemoteUrlsForSchema = uischema => {
  if (uischema.options?.remoteChoicesUrl) {
    return [uischema.options?.remoteChoicesUrl];
  } else if (uischema.elements) {
    return uischema.elements.reduce((urls, child) => {
      return [...new Set([...urls, ...getRemoteUrlsForSchema(child)])]; // de-dup
    }, []);
  } else {
    return [];
  }
};

const getAllRemoteUrls = displaySchemas => {
  return Object.values(displaySchemas).reduce((urls, obj) => {
    const newUrls = obj?.ui_schema ? getRemoteUrlsForSchema(obj.ui_schema) : [];
    return [...new Set([...urls, ...newUrls])]; // de-dup
  }, []);
};

const setDataReducer = (state, update) => {
  return { ...state, [update.url]: update.data };
};

const combineChoices = (uischema, remoteChoices) => {
  // set the fetched data directly in the ui schemas so we don't have to pass it through all the tree renderers
  if (uischema.options?.remoteChoicesUrl) {
    uischema.options.choices = remoteChoices[uischema.options.remoteChoicesUrl];
  } else if (uischema.elements) {
    uischema.elements.forEach(child => {
      combineChoices(child, remoteChoices);
    });
  }
};

function Results(props) {
  const { searchedNames, requirements } = props;

  const { api } = useContext(ApiContext);
  const [displaySchemas, setDisplaySchemas] = useState();

  const { requirementRootProperties, orderedRequirementTypes } = useTypes();

  // load schemas
  useEffect(() => {
    api.get(API_DISPLAY_SCHEMAS).then(response => {
      setDisplaySchemas(response.data);
    });
  }, [api]);

  // load remote data (e.g. investor companies) we'll need to render
  const [remoteChoices, setRemoteChoices] = useReducer(setDataReducer, {});
  const remoteUrls = useMemo(() => displaySchemas && getAllRemoteUrls(displaySchemas), [displaySchemas]);
  useEffect(() => {
    remoteUrls &&
      remoteUrls.forEach(url => {
        api.get(url).then(response => {
          setRemoteChoices({ url: url, data: response.data });
        });
      });
  }, [api, remoteUrls]);
  const remoteChoicesLoaded = remoteUrls && remoteUrls.every(url => remoteChoices[url]);

  const canShow = displaySchemas && searchedNames && requirements && remoteChoicesLoaded;
  // scroll down to show the results after loading
  const wrapperRef = useRef();
  useEffect(() => {
    if (canShow) {
      setTimeout(() => {
        wrapperRef.current.scrollIntoView?.({ behavior: 'smooth' });
      }, 100); // wait a bit for things to render so it scrolls to the top of the results
    }
  }, [canShow]);

  if (canShow) {
    return (
      <div ref={wrapperRef} className="search-results-cards-wrapper">
        {orderedRequirementTypes.map(type => {
          const name = searchedNames[type];
          if (!name) {
            return null;
          }

          const rootProp = requirementRootProperties[type];
          const uiSchema = displaySchemas[type]?.ui_schema;
          if (uiSchema) {
            combineChoices(uiSchema, remoteChoices);
          }

          return (
            <RequirementsCard
              key={type}
              type={type}
              name={name}
              requirements={{ [rootProp]: requirements[rootProp] }}
              dataSchema={displaySchemas[type]?.data_schema}
              uiSchema={uiSchema}
            />
          );
        })}
      </div>
    );
  } else {
    return null;
  }
}

export default Results;
