import { Layout } from 'antd';
import React from 'react';
import FAQLink from './faq/FAQLink';
import FooterLogo from './FooterLogo';

const { Footer } = Layout;

function AppFooter(props) {
  return (
    <Footer style={{ backgroundColor: props.backgroundColor ? props.backgroundColor : 'white' }}>
      {props.showFAQLink && <FAQLink />}
      <FooterLogo />
    </Footer>
  );
}
export default AppFooter;
