import './Login.less';
import React, { useContext } from 'react';
import { Typography } from 'antd';
import OIDCLogin from './OIDCLogin';
import DevLogin from './DevLogin';
import loginHero from './login_hero.png';
import AppFooter from '../AppFooter';
import NavSidebar from '../NavSidebar';
import { ApplicationConfigurationContext } from '../contexts/ApplicationConfigurationProvider';
import { ApiContext } from '../contexts/ApiProvider';

function AuthComponent(props) {
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);
  if (applicationConfiguration.oidc_enabled) {
    return <OIDCLogin {...props} />;
  } else {
    return <DevLogin {...props} />;
  }
}

function Login(props) {
  const { user } = useContext(ApiContext);
  const { Text } = Typography;

  return (
    <React.Fragment>
      {user ? <NavSidebar /> : null}
      <div className="flex-container">
        <div className="flex-item-left">
          <h1 className="login-title">MISMO e-Eligibility Exchange</h1>
          <Text className="login-body">
            The MISMO e-Eligibility Exchange provides centralized access to criteria that impact digital closings. It
            serves as a central source of information on counterparty requirements, eNotarization regulations, county
            recording requirements, settlement branch readiness, and title underwriter restrictions. It provides all
            MISMO members - free of charge - the information required to confidently determine how digital their
            closings can be. For more information, please see the{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={'https://www.mismo.org/standards-resources/digital-mortgage-resource-center/e-eligibility'}
            >
              MISMO e-Eligibility Exchange FAQs.
            </a>
            <br />
            <br />
            Click Sign In button below to contribute your data.
          </Text>
          <br />
          <br />
          {!user && <AuthComponent {...props} />}
        </div>
        <div className="flex-item-right">
          <img className="login-image" src={loginHero} alt="Powered by" />
        </div>
      </div>
      <div className="login-footer">
        <AppFooter backgroundColor="#f0f2f5" />
      </div>
    </React.Fragment>
  );
}

export default Login;
