import React, { useContext, useEffect, useState } from 'react';

import { Breadcrumb, Button, Form, Modal, Space, Spin, Typography } from 'antd';
import { ApiContext, API_USERS } from '../contexts/ApiProvider';
import { Link, useHistory, useParams } from 'react-router-dom';
import { generateOnValuesChange, SuperAdminFormFields } from './SuperAdminFormCommon';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;
const { confirm } = Modal;

const RemoveSuperAdminButton = props => {
  const { currentUser, confirmDeletion, superAdminId } = props;

  if (currentUser.isSuperAdmin() && currentUser.id !== superAdminId) {
    return (
      <Button type="link" onClick={confirmDeletion} data-cy="remove">
        Remove Super Admin
      </Button>
    );
  } else {
    return null;
  }
};

function SuperAdminEdit() {
  const { id: superAdminId } = useParams();

  const history = useHistory();
  const { api, user: currentUser } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const [apiErrors, setApiErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false); // let the super admin know errors occured
  const [superAdmin, setSuperAdmin] = useState({});

  const setErrors = errors => {
    setLoading(false);
    setApiErrors(errors);
    setShowWarning(true);
  };

  const saveUser = async attributes => {
    try {
      await api.put(`${API_USERS}/${superAdminId}`, { user: attributes });
      return true;
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setLoading(true);
    setApiErrors({});
    setShowWarning(false);

    if (await saveUser(values)) {
      history.push('/super_admins');
    }
  };

  const onValuesChange = generateOnValuesChange(apiErrors, setApiErrors, showWarning, setShowWarning, form);

  const removeSuperAdmin = async () => {
    setLoading(true);
    await api.delete(`${API_USERS}/${superAdminId}`);
    history.push('/super_admins');
  };

  const confirmDeletion = () => {
    confirm({
      icon: null,
      title: 'Are you sure?',
      onOk: removeSuperAdmin,
      okText: 'Remove Super Admin',
      content: 'Removing this Super Admin cannot be undone.'
    });
  };

  useEffect(() => {
    api.get(`${API_USERS}/${superAdminId}`).then(response => {
      const apiData = response.data;
      setSuperAdmin(apiData);
      form.setFieldsValue(apiData);
      setLoading(false);
    });
  }, [api, superAdminId, form]);

  return (
    <LayoutBasic>
      <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/super_admins">Super Admins</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {superAdmin.first_name} {superAdmin.last_name}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading}>
        <Title>Edit Super Admin</Title>
        <Space direction="vertical">
          <Form
            form={form}
            layout="vertical"
            size="large"
            onFinish={onFormFinish}
            onFinishFailed={() => {
              setShowWarning(true);
            }} // just draw attention to FE errors
            onValuesChange={onValuesChange}
            requiredMark={false}
            style={{ maxWidth: 324 }}
          >
            <Text>
              Super Admins may add/remove Companies, Super Admins, Company Admins, Contributors and edit state and
              county e-Eligibility requirements.
            </Text>
            <SuperAdminFormFields apiErrors={apiErrors} />
            {showWarning && <Text type="danger">Could not save Super Admin. See messages above.</Text>}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={loading} data-cy="save-superadmin">
                  Save Changes
                </Button>
                <RemoveSuperAdminButton
                  currentUser={currentUser}
                  confirmDeletion={confirmDeletion}
                  superAdminId={superAdminId}
                />
              </Space>
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </LayoutBasic>
  );
}

export default SuperAdminEdit;
