import React, { useContext, useState } from 'react';

import { Button, Form, message, Space, Typography } from 'antd';
import { ApiContext, API_USERS } from '../contexts/ApiProvider';
import { useHistory } from 'react-router-dom';
import { generateOnValuesChange, SuperAdminFormFields } from './SuperAdminFormCommon';
import LayoutBasic from '../layouts/LayoutBasic';

const { Title, Text } = Typography;

function SuperAdminNew() {
  const history = useHistory();
  const { api } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false); // let the user know errors occured

  const setErrors = errors => {
    setLoading(false);
    setApiErrors(errors);
    setShowWarning(true);
  };

  const saveUser = async attributes => {
    try {
      await api.post(API_USERS, { user: { ...attributes, super_admin: true } });
      setLoading(false);
      message.success('Success - new Super Admin created.');
      history.push(`/super_admins`);
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setLoading(true);
    setApiErrors({});
    setShowWarning(false);

    await saveUser(values);
  };

  const onValuesChange = generateOnValuesChange(apiErrors, setApiErrors, showWarning, setShowWarning, form);

  return (
    <LayoutBasic>
      <Title>Add Super Admin</Title>
      <Space direction="vertical">
        <Form
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFormFinish}
          onFinishFailed={() => {
            setShowWarning(true);
          }} // just draw attention to FE errors
          onValuesChange={onValuesChange}
          requiredMark={false}
          style={{ maxWidth: 324 }}
        >
          <Text>
            Add a new Super Admin to the MISMO e-Eligibility Exchange. Super Admins may add/remove Companies, Super
            Admins, Company Admins, Contributors and edit state and county e-Eligibility requirements.
          </Text>
          <SuperAdminFormFields apiErrors={apiErrors} />
          {showWarning && <Text type="danger">Could not add Super Admin. See messages above.</Text>}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} data-cy="add-superadmin">
              Add Super Admin
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </LayoutBasic>
  );
}

export default SuperAdminNew;
