import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ApiContext } from '../contexts/ApiProvider';
import { ApplicationConfigurationContext } from '../contexts/ApplicationConfigurationProvider';

function DevLogin(props) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState();

  const { api, setJwt, user, setUser } = useContext(ApiContext);
  const { applicationConfiguration } = useContext(ApplicationConfigurationContext);

  const onSubmit = e => {
    e.preventDefault();

    api
      .post(applicationConfiguration.developer_callback_path, { email: email })
      .then(response => {
        setJwt(response.headers.authorization.match(/Bearer (.*)/)[1]);
        setUser(response.data.user);
      })
      .catch(err => {
        setError(true);
      });
  };

  const redirectPath = (props.location.state && props.location.state.referrer) || '/';

  return user ? (
    <Redirect to={redirectPath} />
  ) : (
    <form onSubmit={onSubmit}>
      <h2>Login</h2>
      {error && <div>Unable to login</div>}
      <div>
        <input
          type="email"
          name="email"
          value={email}
          placeholder="email"
          data-test="userEmailInput"
          required
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <button type="submit">Login</button>
    </form>
  );
}

export default DevLogin;
