import React, { useContext, useEffect, useState } from 'react';

import { Breadcrumb, Button, Form, Input, Modal, Radio, Select, Space, Spin, Typography } from 'antd';
import { ApiContext, API_COMPANIES } from '../contexts/ApiProvider';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTypes } from '../common/Types';
import LayoutBasic from '../layouts/LayoutBasic';

const { Text } = Typography;
const { confirm } = Modal;

function CompanyEdit() {
  const { id: companyId } = useParams();

  const history = useHistory();
  const { api } = useContext(ApiContext);

  const [form] = Form.useForm();
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [apiErrors, setApiErrors] = useState({});

  const { alphabetizedCompanyTypes, humanizedCompanyTypes, uneditableCompanyTypes } = useTypes();

  const companyTypeOptions = alphabetizedCompanyTypes
    .filter(companyType => !uneditableCompanyTypes.includes(companyType))
    .map(companyType => (
      <Select.Option key={companyType} value={companyType}>
        {humanizedCompanyTypes[companyType]}
      </Select.Option>
    ));

  useEffect(() => {
    api.get(`${API_COMPANIES}/${companyId}`).then(response => {
      const fields = {
        id: response.data.id,
        name: response.data.fullName,
        company_types: response.data.extensions.eEligibilityRoleTypes,
        api_enabled: response.data.extensions.apiEnabled
      };
      setCompany(fields);
      form.setFieldsValue(fields);
      setLoading(false);
    });
  }, [api, companyId, form]);

  const setErrors = errors => {
    setLoading(false);
    setApiErrors(errors);
  };

  const saveCompany = async attributes => {
    try {
      await api.put(`${API_COMPANIES}/${companyId}`, { company: attributes });
      return true;
    } catch (error) {
      error.response && setErrors(error.response.data);
    }
  };

  const onFormFinish = async values => {
    setLoading(true);
    setApiErrors({});

    if (await saveCompany(values)) {
      history.push(`/companies/${companyId}`);
    }
  };

  const onValuesChange = changedValues => {
    // clear the api-side error after a change to reset for FE-side validations
    for (const key in changedValues) {
      if (apiErrors[key]) {
        delete apiErrors[key];
        setApiErrors({ ...apiErrors });
      }
    }
  };

  const removeCompany = async () => {
    setLoading(true);
    await api.delete(`${API_COMPANIES}/${companyId}`);
    history.push(`/companies`);
  };

  const confirmDeletion = () => {
    confirm({
      icon: null,
      title: 'Are you sure?',
      onOk: removeCompany,
      okText: 'Remove Company',
      content: 'Removing this Company cannot be undone.'
    });
  };

  return (
    <LayoutBasic>
      <Breadcrumb separator="/" style={{ paddingBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to="/companies">Companies</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/companies/${companyId}`}>{company.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading}>
        <Space direction="vertical">
          <Form
            form={form}
            initialValues={{ api_enabled: false }}
            layout="vertical"
            size="large"
            onFinish={onFormFinish}
            onValuesChange={onValuesChange}
            requiredMark={false}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Form.Item
              name="name"
              label="Company name"
              help={apiErrors['name']}
              validateStatus={apiErrors['name'] && 'error'}
              rules={[{ required: true, message: 'Company name is required.' }]}
            >
              <Input data-cy="company" />
            </Form.Item>
            <Form.Item name="company_types" label="Industry segment(s)">
              <Select mode="multiple" placeholder="Select one or more" data-cy="industry-segment">
                {companyTypeOptions}
              </Select>
            </Form.Item>
            <Form.Item label="Access to API">
              <Space direction="vertical">
                <Text type="secondary">Grant all Company Admins with the ability to create and revoke API keys.</Text>
                <Form.Item name="api_enabled" noStyle>
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" data-cy="save">
                  Save Changes
                </Button>
                <Button type="link" onClick={confirmDeletion} data-cy="remove">
                  Remove Company
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Space>
      </Spin>
    </LayoutBasic>
  );
}

export default CompanyEdit;
