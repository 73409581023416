import React, { useEffect, useState } from 'react';
import { Space, Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { useJsonForms } from '@jsonforms/react';
import { Resolve, toDataPath } from '@jsonforms/core';
import isEmpty from 'lodash/isEmpty';

import CheckTreeNodeLabel, { CheckTreeNodeAccepted, CheckTreeNodeNotAccepted } from './CheckTreeNodeLabel';
import missingIcon from '../images/search_missing.svg';
import acceptedIcon from '../images/search_accepted.svg';
import notAcceptedIcon from '../images/search_not_accepted.svg';
import AdditionalNotes from './AdditionalNotes';
import { convertPathToTag } from '../common/dataTestUtil';

const dataStatus = data => {
  if (data) {
    return 'accepted';
  } else if (data === false) {
    return 'not_accepted';
  } else {
    return 'missing';
  }
};

const icons = {
  accepted: acceptedIcon,
  not_accepted: notAcceptedIcon,
  missing: missingIcon
};

const descriptions = {
  accepted: 'Accepted', // TODO figure out how "with conditions" will work here?
  not_accepted: 'Not Accepted',
  missing: 'Information not yet provided'
};

const treeLabels = {
  all: 'Criteria and requirements',
  exception: 'Exceptions, criteria, and requirements'
};

const getChoiceTreeChildren = (uischema, requirements) => {
  // return an array of tree node elements rendered from the choices
  const path = toDataPath(uischema.scope);
  const savedValues = Resolve.data(requirements, path); // e.g. array of company ids
  if (!Array.isArray(savedValues)) {
    return [];
  }
  const choices = uischema.options.choices;

  const labels = savedValues
    .map(value => {
      return choices.find(choice => choice.value === value)?.label;
    })
    .filter(val => val)
    .sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
  return labels.map((label, index) => {
    return {
      key: `${path}-${index}`,
      title: (
        <CheckTreeNodeAccepted label={label} dataPath={toDataPath(uischema.scope) + '_' + label.replace(/\s/g, '')} />
      )
    };
  });
};

const getElementLabel = (element, value) => {
  if (element.options?.valueBasedLabels) {
    const labels = element.options?.valueBasedLabels;
    return value ? labels.true : labels.false;
  } else if (element.options?.choices && !element.label) {
    return element.options?.choices.find(choice => choice.value === value)?.label;
  } else if (typeof value === 'string') {
    return (
      <span>
        {element.label}
        <br />
        <AdditionalNotes text={value} defaultExpanded={true} />
      </span>
    );
  } else {
    return element.label;
  }
};

const getTreeChildren = (layout, schema, path, requirements, onException) => {
  // returns a list of <TreeNode> elements directly (along with any others nested via 'elements')
  // to make them show up in the AntD Tree correctly
  if (isEmpty(layout.elements)) {
    return [];
  }

  // only shows child elements if the data resolves to true (e.g. a list of approved providers)
  const limitListToTrue = layout.options?.limitListToTrue;
  // show child elements with "not accepted" marks if true and vis versa (e.g. a list of restrictions)
  const invertListValues = layout.options?.invertList;

  return layout.elements
    .map((child, index) => {
      child.options = { ...(child.options || {}), treeNode: true };
      const value = child.scope && Resolve.data(requirements, toDataPath(child.scope));
      const showChild = value !== '' && (limitListToTrue || child.options?.onlyShowIfTrue ? value : true);

      if (showChild) {
        const showCheck = !child.options?.hideCheck; // e.g. hide it on the list of restrictions
        const label = getElementLabel(child, value);

        const accepted = invertListValues ? !value : value;
        if (accepted === false && showCheck) {
          onException(); // should show that there are Exceptions at the top level
        }
        const nextPath = `${path}-${index}`;

        let children = [];
        if (child.elements && value !== false) {
          children = children.concat(getTreeChildren(child, schema, nextPath, requirements, onException));
        }
        if (child.options?.choices) {
          children = children.concat(getChoiceTreeChildren(child, requirements));
        }

        // If a list is empty, don't show the header (e.g. "Exceptions: <blank>")
        if (child.options?.limitListToTrue && children.length === 0) {
          return null;
        }

        // If no choice is selected don't show the label (only applies to optional fields)
        if (child.options?.choices && value?.length === 0) {
          return null;
        }

        return {
          key: nextPath,
          title: showCheck ? (
            <CheckTreeNodeLabel accepted={accepted} label={label} dataPath={toDataPath(child.scope)} />
          ) : (
            <div data-test={convertPathToTag(toDataPath(child.scope))}>{label}</div>
          ),
          children: children
        };
      } else {
        return null;
      }
    })
    .filter(child => child);
};

const getTreeChildrenKeys = treeChildren => {
  // Takes in a list of <TreeNode> elements and returns a list of all node keys contained in those
  // elements along with their all descendants recurisvely.
  const treeChildrenKeys = [];
  if (Array.isArray(treeChildren)) {
    for (const child of treeChildren) {
      treeChildrenKeys.push(child.key);
      treeChildrenKeys.push(...getTreeChildrenKeys(child.children));
    }
  }
  return treeChildrenKeys;
};

function RequirementsTree(props) {
  const { uischema, rootSchema } = props;

  const requirements = useJsonForms().core.data;
  const [hasExceptions, setHasExceptions] = useState(false);
  const [treeChildren, setTreeChildren] = useState(null);

  useEffect(() => {
    setHasExceptions(false);
    const onException = () => {
      setHasExceptions(true); // if one of the children is a not accepted node, it will call this
    };
    setTreeChildren(getTreeChildren(uischema, rootSchema, 'root', requirements, onException));
  }, [requirements, uischema, rootSchema]);

  const treeTitle = (
    <span style={{ color: '#3E6BE0', fontWeight: 'bold' }}>
      {hasExceptions ? (
        <CheckTreeNodeNotAccepted label={treeLabels.exception} dataPath={toDataPath(uischema.scope)} />
      ) : (
        <CheckTreeNodeLabel label={treeLabels.all} dataPath={toDataPath(uischema.scope)} />
      )}
    </span>
  );

  const treeData =
    treeChildren && treeChildren.length > 0
      ? [
          {
            key: 'root',
            title: treeTitle,
            children: treeChildren
          }
        ]
      : null;

  return (
    treeData && (
      <Tree
        defaultExpandAll={process.env.REACT_APP_EXPAND_SEARCH_RESULTS_TREE || false}
        defaultExpandedKeys={getTreeChildrenKeys(treeChildren)}
        defaultExpandParent={false}
        selectable={false}
        switcherIcon={<DownOutlined />}
        showLine={{ showLeafIcon: false }}
        showIcon={false}
        treeData={treeData}
      />
    )
  );
}

function CheckTreeControl(props) {
  const { label, visible, data, rootSchema, uischema } = props;
  const status = dataStatus(data); // accepted, not_accepted, or missing

  const icon = icons[status];
  return (
    visible && (
      <div>
        <Space data-test={convertPathToTag(toDataPath(uischema.scope))}>
          {icon && <img src={icon} alt={descriptions[status]} />}
          {`${label} ${descriptions[status]}`}
        </Space>
        {status === 'accepted' && uischema.elements && <RequirementsTree uischema={uischema} rootSchema={rootSchema} />}
      </div>
    )
  );
}
export default CheckTreeControl;
