import React, { useEffect, useState } from 'react';
import { API_COMPANIES } from '../contexts/ApiProvider';
import { Space, Spin, Tag, Typography } from 'antd';

const { Text } = Typography;

function NewApiKeyModal(props) {
  const { api, user } = props; // can't use context directly because modal content isn't rendering in the provider hierarchy

  const [keyInfo, setKeyInfo] = useState();
  const companyId = user.company.id;

  useEffect(() => {
    api.post(`${API_COMPANIES}/${companyId}/api_tokens`).then(response => {
      setKeyInfo(response.data);
    });
  }, [api, companyId]);

  return (
    <Spin spinning={!keyInfo}>
      {keyInfo && (
        <>
          <Space direction="vertical" size="middle">
            <Text>
              Note: Please store these credentials in a safe place. For your protection, we will not show them again. If
              you misplace your API key, you should disable this API key and generate a new one.
            </Text>
            <div>
              <Text strong={true}>API Key:</Text> <Text copyable>{keyInfo.apiKey}</Text>
              <br />
              <Text strong={true}>API Secret:</Text> <Text copyable>{keyInfo.apiSecret}</Text>
            </div>
            <Tag color="#0BA169">Enabled</Tag>
          </Space>
        </>
      )}
    </Spin>
  );
}
export default NewApiKeyModal;
