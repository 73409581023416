import React from 'react';
import { renderChildren } from '../common/jsonFormUtil';
import { formItemStyle } from './GeneratorCommon';

const GroupLayout = props => {
  const { uischema, schema, path, visible } = props;

  if (uischema.options.checkboxList) {
    for (const i in uischema.elements) {
      const element = uischema.elements[i];
      if (!element.options) {
        element.options = {};
      }
      element.options.checkbox = true; // set all controls in group to have a checkbox
    }
  }

  return (
    visible && (
      <div style={{ marginBottom: 24, ...formItemStyle(uischema) }}>
        {uischema.label}
        {renderChildren(uischema, schema, '', path)}
      </div>
    )
  );
};
export default GroupLayout;
