import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Result } from 'antd';
import { ApiContext } from './contexts/ApiProvider';

function RootRedirector() {
  const { user } = useContext(ApiContext);

  if (!user) {
    return <Redirect to="/login" />;
  } else if (user.isSuperAdmin()) {
    return <Redirect to="/companies" />;
  } else if (user.isCompanyAdmin()) {
    return <Redirect to={`/companies/${user.company.id}`} />;
  } else if (user.isCompanyContributor()) {
    return <Redirect to="/requirements" />;
  } else if (user.isViewer()) {
    return <Redirect to="/search" />;
  } else {
    return <Result status="500" title="Sorry, something went wrong" subTitle="Unknown Role" />;
  }
}

export default RootRedirector;
