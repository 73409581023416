import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { ApiContext, API_COMPANIES, API_REQUIREMENTS_REQUEST } from '../contexts/ApiProvider';
import { useTypes } from '../common/Types';

const { Option } = Select;

const debounceTimeout = 500;

function CompanySelect(props) {
  const { api } = useContext(ApiContext);
  const [options, setOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [nextPage, setNextPage] = useState();
  const [loading, setLoading] = useState(false);

  const { segment, updateSearchingNames, ...elemProps } = props;

  const { humanizedCompanyTypes } = useTypes();

  const fetchOptions = useCallback(
    (searchParams, onLoad) => {
      api
        .get(API_COMPANIES, {
          params: { company_types: [segment], sort_by: 'name', direction: 'asc', ...searchParams }
        })
        .then(response => {
          const currentPage = searchParams.page || 1;
          const totalPages = Math.ceil(response.headers['total'] / response.headers['per-page']);
          if (currentPage < totalPages) {
            setNextPage(currentPage + 1);
          } else {
            setNextPage(null);
          }

          onLoad(
            response.data.map(company => {
              return { label: company.fullName, value: company.id };
            })
          );

          setLoading(false);
        });
    },
    [api, segment]
  );
  const fetchOptionsDebounced = useMemo(() => debounce(fetchOptions, debounceTimeout), [fetchOptions]);

  // load initial options, or load for the current search term when it changes
  useEffect(() => {
    setLoading(true);
    setNextPage(null);
    if (searchTerm) {
      // only showing options after they start typing now
      fetchOptionsDebounced({ name: searchTerm }, setOptions);
      return fetchOptionsDebounced.cancel;
    }
  }, [api, searchTerm, setOptions, fetchOptionsDebounced]);

  const appendOptions = newOptions => {
    setOptions(options.concat(newOptions));
  };

  // fetch next page if needed
  const onScroll = e => {
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (!loading && nextPage) {
        setLoading(true);
        const searchParams = searchTerm ? { name: searchTerm } : {};
        fetchOptions({ page: nextPage, ...searchParams }, appendOptions);
      }
    }
  };

  const handleMissingRequest = (_value, option) => {
    if (option?.key === 'missing') {
      const params = {
        name: option.label,
        industry_segment: segment
      };
      api.post(API_REQUIREMENTS_REQUEST, params);
    }
  };

  const updateCompanyName = (_value, option) => {
    updateSearchingNames({ [segment]: option?.label });
  };

  return (
    <Form.Item label={humanizedCompanyTypes[segment]} {...elemProps}>
      <Select
        allowClear={true}
        filterOption={false}
        loading={loading}
        notFoundContent=""
        optionLabelProp="label"
        onChange={updateCompanyName}
        onClear={() => setSearchTerm(null)}
        onPopupScroll={onScroll}
        onSearch={setSearchTerm}
        onSelect={handleMissingRequest}
        showArrow={false}
        showSearch={true}
        size="large"
      >
        {options.map(choice => (
          <Option key={choice.value} value={choice.value} label={choice.label}>
            {choice.label}
          </Option>
        ))}
        {!loading && searchTerm && options.length === 0 && (
          <Option key="missing" value={''} label={searchTerm} style={{ fontStyle: 'italic' }}>
            Request information for {searchTerm}
          </Option>
        )}
        {nextPage && loading && (
          <Option key="loading" className="italicized">
            <Spin spinning={true} />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
}

export default CompanySelect;
