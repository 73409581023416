import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ApiProvider } from './contexts/ApiProvider';
import { ApplicationConfigurationProvider } from './contexts/ApplicationConfigurationProvider';
import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <ApiProvider>
          <ApplicationConfigurationProvider>
            <App />
          </ApplicationConfigurationProvider>
        </ApiProvider>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
