import React, { useContext, useEffect, useState } from 'react';
import { Col, Input, Row, Space, Table, Typography } from 'antd';
import { ApiContext, API_STATES } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import LayoutBasic from '../layouts/LayoutBasic';
import { formatDateTime } from '../common/DateTime';

const { Title, Text } = Typography;
const { Search } = Input;

const columns = [
  {
    title: 'State',
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    key: 'name',
    sortDirections: ['ascend', 'descend', 'ascend'], // requires some sort of ordering
    sorter: true,
    render: (_, state) => {
      return <Link to={`/requirements/states/${state.id}`}>{state.stateName}</Link>;
    }
  },
  {
    title: 'Last Contributed', // TODO add these values after we can get them from the api
    dataIndex: ['extensions', 'eEligibilityRequirementsUpdatedDatetime'],
    key: 'last_contributed_at',
    sortDirections: ['descend', 'ascend', 'descend'], // requires some sort of ordering
    sorter: true,
    render: time => formatDateTime(time)
  }
];

const apiDirections = {
  ascend: 'asc',
  descend: 'desc'
};

function StateIndex() {
  const { api } = useContext(ApiContext);

  const [tableParams, setTableParams] = useState({ sort_by: 'name', page: 1 });
  const [searchParams, setSearchParams] = useState({});

  const [states, setStates] = useState([]);
  const [pageData, setPageData] = useState({ pageSize: 1, total: 0 });
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const onTableChange = (pagination, filters, sorter) => {
    // update the sort/pagination params
    const params = {
      sort_by: sorter.columnKey,
      direction: apiDirections[sorter.order],
      page: pagination.current,
      per_page: pagination.pageSize
    };
    setTableParams(params);
  };

  const updateSearchParams = query => {
    setTableParams(prevParams => {
      return { ...prevParams, page: 1 }; // reset page
    });
    setSearchParams(
      query.length > 0 ? { name: query } : {} // update the name search param
    );
  };

  useEffect(() => {
    setLoading(true);
    const indexParams = { ...tableParams, ...searchParams };
    api.get(API_STATES, { params: indexParams }).then(response => {
      setStates(response.data);
      setPageData({
        current: tableParams.page,
        pageSize: response.headers['per-page'],
        total: response.headers.total
      });
      setLoading(false);
    });
  }, [api, tableParams, searchParams]);

  const onRowClick = record => {
    return {
      onClick: () => {
        history.push(`/requirements/states/${record.id}`);
      }
    };
  };

  return (
    <LayoutBasic>
      <Title data-test="State_Requirements">State Requirements</Title>
      <Row style={{ padding: 8 }}>
        <Col span={12}>
          <Text data-test="State_Requirements_Text">
            Select a location below to submit digital mortgage closing criteria and requirements to the MISMO
            e-Eligibility Exchange.
          </Text>
        </Col>
        <Col span={12}>
          <Space style={{ float: 'right' }}>
            <Search
              allowClear
              enterButton
              loading={loading && searchParams.name}
              onSearch={updateSearchParams}
              placeholder="Search"
              size="large"
              data-cy="search"
            />
          </Space>
        </Col>
      </Row>
      <Table
        bordered
        columns={columns}
        dataSource={states}
        loading={loading}
        onChange={onTableChange}
        onRow={onRowClick}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50'],
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} entries`,
          ...pageData
        }}
        rowKey="id"
        data-test="States_Table"
      />
    </LayoutBasic>
  );
}

export default StateIndex;
