import { Form, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { formItemStyle } from './GeneratorCommon';
import { ApiContext } from '../contexts/ApiProvider';
import { convertPathToTag } from '../common/dataTestUtil';

const { Option } = Select;

const SelectControl = props => {
  const { api } = useContext(ApiContext);

  const { handleChange, path, label, visible, uischema, schema } = props;
  const [choicesList, setChoicesList] = useState(false);

  useEffect(() => {
    const { choices, remoteChoicesUrl } = uischema.options;

    if (choices) {
      // load list of selectable responses from ui schema
      setChoicesList(choices);
    } else if (remoteChoicesUrl) {
      // or load selectable responses from a url if needs to be dynamic (e.g. investor list)
      api.get(uischema.options.remoteChoicesUrl).then(response => {
        setChoicesList(response.data);
      });
    } else {
      console.log('No choices were provided');
      setChoicesList([]);
    }
  }, [api, uischema.options]);

  const rules = uischema.options?.requireSelection
    ? [{ required: true, message: 'At least one selection is required.' }]
    : null;

  return (
    visible &&
    choicesList && (
      <Form.Item initialValue={[]} name={path.split('.')} label={label} style={formItemStyle(uischema)} rules={rules}>
        <Select
          mode={schema.type === 'array' ? 'multiple' : null}
          onChange={values => handleChange(path, values)}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          data-test={convertPathToTag(path)}
        >
          {choicesList.map(choice => (
            <Option key={choice.value} value={choice.value}>
              {choice.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    )
  );
};
export default SelectControl;
