import React, { useContext, useState } from 'react';

import { JsonForms, withJsonFormsControlProps, withJsonFormsLayoutProps } from '@jsonforms/react';
import { and, optionIs, rankWith, schemaTypeIs, uiTypeIs } from '@jsonforms/core';
import { vanillaCells } from '@jsonforms/vanilla-renderers';
import { verticalLayoutTester } from '@jsonforms/vanilla-renderers';

import { Button, Checkbox, Form, Input, message, Modal, Space, Spin, Typography } from 'antd';
import { ApiContext } from '../contexts/ApiProvider';
import { Link, useHistory } from 'react-router-dom';
import LayoutBasic from '../layouts/LayoutBasic';
import CheckboxControl from './CheckboxControl';
import GroupLayout from './GroupLayout';
import RadioControl from './RadioControl';
import SelectControl from './SelectControl';
import TextBox from './TextBox';
import VerticalLayout from './VerticalLayout';

const { TextArea } = Input;
const { confirm } = Modal;
const { Text, Title } = Typography;

const renderers = [
  { tester: verticalLayoutTester, renderer: withJsonFormsLayoutProps(VerticalLayout) },
  { tester: uiTypeIs('Group'), renderer: withJsonFormsLayoutProps(GroupLayout) },
  { tester: schemaTypeIs('boolean'), renderer: withJsonFormsControlProps(RadioControl) },
  {
    tester: rankWith(2, and(schemaTypeIs('boolean'), optionIs('checkbox', true))),
    renderer: withJsonFormsControlProps(CheckboxControl)
  },
  { tester: optionIs('dropdown', true), renderer: withJsonFormsControlProps(SelectControl) },
  { tester: schemaTypeIs('array'), renderer: withJsonFormsControlProps(SelectControl) },
  { tester: schemaTypeIs('string'), renderer: withJsonFormsControlProps(TextBox) }
];

const RemoveRequirementsButton = props => {
  const { confirmDeletion, requirements, showRemoveRequirements } = props;

  if (showRemoveRequirements && requirements) {
    return (
      <Button type="link" onClick={confirmDeletion} data-cy="remove">
        Remove Requirements
      </Button>
    );
  } else {
    return null;
  }
};

function RequirementsForm(props) {
  const {
    title,
    type,
    requirements,
    schema,
    showAdditionalRequirements,
    showRemoveRequirements,
    description,
    postTo,
    afterSuccessPath,
    finishedLoadingRequirements
  } = props;

  const [showWarning, setShowWarning] = useState(false);
  const { api } = useContext(ApiContext);

  const history = useHistory();

  const rootProperty = schema && Object.keys(schema.data_schema.properties)[0];

  const submitData = values => {
    delete values.submissionConfirmation; // only needed for the UI

    api.post(postTo, values).then(response => {
      message.success('Success - requirements saved.');
      history.push(afterSuccessPath);
    });
  };

  const confirmSubmission = values => {
    confirm({
      icon: null,
      title: 'Are you sure?',
      onOk: () => submitData(values),
      okText: 'Publish Requirements',
      okButtonProps: { 'data-test': 'Publish_Requirements_Ok' },
      cancelButtonProps: { 'data-test': 'Publish_Requirements_Cancel' },
      content: 'These requirements will be made public to all MISMO members.',
      className: 'Publish_Requirements_Confirmation'
    });
  };

  const removeRequirements = async () => {
    api.delete(`${postTo}?type=${type}`).then(response => {
      message.success('Success - requirements removed.');
      history.push(afterSuccessPath);
    });
  };

  const confirmDeletion = () => {
    confirm({
      icon: null,
      title: 'Are you sure?',
      onOk: removeRequirements,
      okText: 'Remove Requirements',
      okButtonProps: { 'data-test': 'Remove_Requirements_Ok' },
      cancelButtonProps: { 'data-test': 'Remove_Requirements_Cancel' },
      content: 'These requirements will be removed from MISMO e-Eligibility Exchange and no longer publicly available.',
      className: 'Remove_Requirements_Confirmation'
    });
  };

  const [form] = Form.useForm();

  return (
    <LayoutBasic showFAQLink={true}>
      <Spin spinning={!schema || !(finishedLoadingRequirements || requirements)}>
        <Title data-test={type === 'state' ? 'StateName' : type + '_Requirements'}>{title}</Title>
        {schema && (finishedLoadingRequirements || requirements) && (
          <Form
            onFinish={confirmSubmission}
            onFinishFailed={() => {
              setShowWarning(true);
            }} // draw attention to FE errors
            onValuesChange={() => {
              setShowWarning(false);
            }}
            initialValues={requirements}
            form={form}
            layout="vertical"
            requiredMark={false}
            size="large"
            style={{ width: 640 }}
          >
            {description && (
              <Form.Item>
                <Text data-test={'Requirements_Text'}>{description}</Text>
              </Form.Item>
            )}
            <JsonForms
              data={requirements}
              schema={schema.data_schema}
              uischema={schema.ui_schema}
              renderers={renderers}
              cells={vanillaCells}
            />
            {showAdditionalRequirements && (
              <Form.Item
                initialValue={requirements && requirements[rootProperty] ? undefined : ''}
                name={[rootProperty, 'AdditionalRequirements']}
                label="Any additional requirements and criteria (optional):"
                data-test="Additional_Requirements"
              >
                <TextArea rows={3} maxLength="400" data-test="Additional_Requirements_Textarea" />
              </Form.Item>
            )}
            <Form.Item
              name="submissionConfirmation"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Please certify the requirements.'))
                }
              ]}
              valuePropName="checked"
            >
              <Checkbox data-test="Submission_Confirmation">
                I certify that the above information is true and correct and that I am authorized to provide such
                information on behalf of my organization, in accordance with the{' '}
                <Link to="/contributor_terms" target="_blank">
                  terms and conditions
                </Link>
                .
              </Checkbox>
            </Form.Item>
            {showWarning && <Text type="danger">Some fields were not completed.</Text>}
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" size="large" data-test="Publish_Requirements">
                  Publish Requirements
                </Button>
                <RemoveRequirementsButton
                  confirmDeletion={confirmDeletion}
                  requirements={requirements}
                  showRemoveRequirements={showRemoveRequirements}
                />
              </Space>
            </Form.Item>
          </Form>
        )}
      </Spin>
    </LayoutBasic>
  );
}

export default RequirementsForm;
