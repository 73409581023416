import { Layout, Typography } from 'antd';
import React from 'react';
import FooterLogo from './FooterLogo';

const { Footer } = Layout;
const { Text } = Typography;

function SearchFooter() {
  return (
    <Footer className="search-footer">
      <Text style={{ fontSize: '12px' }}>
        For more information, please see the{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href={'https://www.mismo.org/standards-resources/digital-mortgage-resource-center/e-eligibility'}
        >
          MISMO e-Eligibility Exchange FAQs
        </a>
        {', '}
        <a target="_blank" rel="noreferrer" href={'https://app.smartsheet.com/b/form/03ef63fa9a8a4af7abf81ab66d99b1e2'}>
          report a problem
        </a>
        {', '}
        or contact us at <a href={'mailto:eee@mismo.org'}>eee@mismo.org</a>.
      </Text>
      <div style={{ padding: '18px' }}>
        <FooterLogo />
      </div>
    </Footer>
  );
}
export default SearchFooter;
