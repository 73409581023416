import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Col, Layout, Row } from 'antd';
import { ApiContext } from '../contexts/ApiProvider';
import AppFooter from '../AppFooter';

function Terms({ children, postUrl, redirectUrl, termsField }) {
  const { Content } = Layout;

  const { api, setUser, user } = useContext(ApiContext);
  const needsAcceptance = user && !user[termsField]; // can view terms again after accepting

  const history = useHistory();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const saveTerms = async () => {
    try {
      const newUser = await api.post(postUrl, {});
      setUser({ ...user, [termsField]: newUser.data[termsField] });
      history.replace(redirectUrl);
    } catch (error) {
      console.log(error.response.data.err);
    }
  };

  const onAcceptChange = e => {
    setTermsAccepted(e.target.checked);
  };

  return (
    <Layout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="document-content">
          <Content className="site-layout-background" style={{ padding: '24px 24px 4px 24px' }}>
            {children}
            {needsAcceptance && (
              <Row justify="center" style={{ marginBottom: 30 }}>
                <Col className="terms-checkbox" style={{ display: 'flex', alignItems: 'center', marginRight: 15 }}>
                  <Checkbox onChange={onAcceptChange}>I agree with the Terms & Conditions</Checkbox>
                </Col>
                <Col>
                  <Button size="large" type="primary" onClick={saveTerms} disabled={!termsAccepted}>
                    Continue
                  </Button>
                </Col>
              </Row>
            )}
          </Content>
        </div>
      </div>
      <AppFooter backgroundColor="#f0f2f5" />
    </Layout>
  );
}

export default Terms;
